import React from "react";
import Dropdown from "./Dropdown";

const DropdownHelper = ({ selectedVal, setSelectedVal, content, title, changeOpen }) => {
  return (
    <div class={`mx-4`}>

      <Dropdown
        selectedVal={selectedVal}
        setSelectedVal={setSelectedVal}
        content={content}
        // changeOpen={changeOpen}
      />
    </div>
  );
};

export default DropdownHelper;
