import { AgChartsReact } from "ag-charts-react";
import React, { useEffect, useState } from "react";

const MultipleLineChart = (props) => {
  console.log("props", props.data);
  const [seriesData, setSeriesData] = useState(props.data);

    useEffect(() => {
      setSeriesData(props.data)
    }, [props])

  const options = {
    title: {
      text: props.text,
      fontSize: 18,
      spacing: 25,
    },
    series: seriesData.map((series) => ({
      type: "line",
      xKey: "date",
      yKey: props.yKey,
      yName: series.name,
      marker: {
        size: 0,
      },
      autoSize: true,
      data: series.data,
    })),
    axes: [
      {
        type: "number",
        position: "left",
        gridLine: {
          enabled: true,
          style: [
            {
              stroke: "lightgrey",
              lineDash: [10, 5],
            },
          ],
        },
        // label: {
        // 	formatter: function (params) {
        // 		// Check if params.value is a number and format it as a percentage
        // 		if (!isNaN(params.value)) {
        // 			return params.value.toFixed(2) + "%";
        // 		}
        // 		// Return the original value if it's not a number
        // 		return params.value;
        // 	},
        // },
      },
      {
        type: "category",

        position: "bottom",
        label: {
          autoRotate: false,
        },
        gridLine: {
          enabled: true,
          style: [
            {
              stroke: "lightgrey",
              lineDash: [10, 5],
            },
          ],
        },
      },
    ],

    legend: {
      position: "bottom",
    },
  };
  //   console.log("options", options)
  return (
    <div style={{ width: "95%", height: "600px", marginTop:100 }}>
      <AgChartsReact options={options} />
    </div>
  );
};

export default MultipleLineChart;
