import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Signup from "./screens/SIgnup";
import Dashboard_live from "./screens/Dashboard_live";
import Dashboard_bt from "./screens/Dashboard_bt";
import { Provider } from "react-redux";
import { store } from "./redux/store.js";
import Instances from "./screens/Instances";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Market from "./screens/Market";
import Allocation from "./screens/Allocation";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Signup />,
  },
  {
    path: "/live_data",
    element: <Dashboard_live />,
  },
  {
    path: "/bt_data",
    element: <Dashboard_bt />,
  },
  {
    path: "/instances",
    element: <Instances />,
  },
  {
    path: "/market",
    element: <Market />,
  },
  {
    path: "/allocation",
    element: <Allocation />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
