import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { dateFilter, headersEditable, textFilter } from "../data";
import ToggleButton from "./ToggleButton";
import CalendarComp from "./Calendar";
import { MdOutlineFileDownload } from "react-icons/md";

const MarketTable = ({
  data,
  setSymbolGraph,
  exchange,
  downloadDailyTvData,
}) => {
  const [rowData, setRowData] = useState([]);
  // console.log("type", typeof data[0].turnover)
  useEffect(() => {
    setRowData(
      data.map((item) => {
        return { ...item, flag: false };
      })
    );
  }, [data]);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    }),
  }));

  const valueFormatter = ({ value }) => {
    if (value === null) {
      return "N/A";
    } else if (typeof value === "number") {
      if (value % 1 === 0) {
        return value.toFixed(0);
      } else return parseFloat(value).toFixed(2);
    } else {
      return value;
    }
  };

  const handleCellClick = (event) => {
    console.log("eventt", event);

    let column = event.colDef.headerName;
    let underlying = event.data.underlying;
    if (column === "Download Daily TV") {
      downloadDailyTvData(underlying);
    }
    if (column === "Plot graph") {
      const state = event.data.flag;
      let updatedData = rowData.map((item) => {
        return {
          ...item,
          flag:
            item.underlying === underlying
              ? !item.flag
              : item.flag
              ? false
              : item.flag,
        };
      });
      setRowData(updatedData);
      if (!state) setSymbolGraph(underlying);
      else setSymbolGraph("all");
    }

    // Toggle the flag state
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "date",
        headerName: "Date",
        flex: 1,
        minWidth: 150,
        filter: "agDateColumnFilter",
        cellRenderer: "agGroupCellRenderer",
        cellRenderer: valueFormatter,
      },
      {
        field: "underlying",
        headerName: "Underlying",
        filter: "agTextColumnFilter",
        flex: 1,
        minWidth: 150,
        cellRenderer: "agGroupCellRenderer",
        cellRenderer: valueFormatter,
      },
      {
        field: "rollingMean_1",
        headerName:
          exchange === "NSE_OPTIONS" ? "NotionalTv(Cr)" : "Turnover(Cr)",
        flex: 1,
        minWidth: 150,
        filter: "agNumberColumnFilter",
        cellRenderer: "agGroupCellRenderer",
        cellRenderer: valueFormatter,
      },
      {
        field: "rollingMean_2",
        headerName: "PremiumTv(cr)",
        hide: exchange === "NSE_OPTIONS" ? false : true,
        flex: 1,
        minWidth: 150,
        filter: "agNumberColumnFilter",
        cellRenderer: "agGroupCellRenderer",
        cellRenderer: valueFormatter,
      },
      {
        field: "percentile_1",
        headerName:
          exchange === "NSE_OPTIONS" ? "NotionalTv_pct" : "Percentile",
        flex: 1,
        minWidth: 150,
        filter: "agNumberColumnFilter",
        cellRenderer: valueFormatter,
      },
      {
        field: "percentile_2",
        headerName: "PremiumTv_pct",
        hide: exchange === "NSE_OPTIONS" ? false : true,
        flex: 1,
        minWidth: 150,
        filter: "agNumberColumnFilter",
        cellRenderer: valueFormatter,
      },
      {
        headerName: "Plot graph",

        field: "plot",
        flex: 1,
        minWidth: 300,
        cellRenderer: (params) => {
          return <ToggleButton flag={params.data.flag} />;
        },
      },
      {
        headerName: "Download Daily TV",

        // field: "download",
        // flex: 1,
        // width:100,
        minWidth: 220,
        cellRenderer: (params) => {
          return (
            <div class="cursor-pointer hover:scale-125">
              <MdOutlineFileDownload size={24}/>
            </div>
          );
        },
      },


    ],
    [rowData]
  );

  return (
    <div class="mt-10">
      <div
        className="ag-theme-alpine"
        style={{
          width: "80%",
          height: 500,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {" "}
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          onCellClicked={handleCellClick}
          popupParent={document.body}
          // onCellValueChanged={filterChange_live}
        />
      </div>
    </div>
  );
};

export default MarketTable;
