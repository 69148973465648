import React, { useState, useEffect, useRef } from "react";
import "./DropdownMenu.css";

const DropdownMenu = (props) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [selectedItems, setSelectedItems] = useState([]);
	const { onChange } = props;
	const dropdownRef = useRef(null);

	const toggleDropdown = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleCheckboxChange = (e) => {
		const item = e.target.value;
		if (e.target.checked) {
			setSelectedItems([...selectedItems, item]);
		} else {
			setSelectedItems(
				selectedItems.filter((selectedItem) => selectedItem !== item)
			);
		}
	};

	useEffect(() => {
		onChange(selectedItems);
	}, [selectedItems, onChange]);

	const closeDropdown = () => {
		setIsDropdownOpen(false);
	};

	useEffect(() => {
		function handleClickOutside(event) {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				closeDropdown();
			}
		}

		window.addEventListener("click", handleClickOutside);
		return () => {
			window.removeEventListener("click", handleClickOutside);
		};
	}, []);

	return (
		// <div className="dropdown" ref={dropdownRef}>
		<div className="dropdown" ref={dropdownRef}>
			<button
				className="dropdown-button"
				onClick={toggleDropdown}
				ref={dropdownRef}
			>
				{selectedItems.length > 0
					? selectedItems.join(", ")
					: "Select Days"}
			</button>
			<div className={`dropdown-content ${isDropdownOpen ? "open" : ""}`}>
				<label>
					<input
						type="checkbox"
						value="MON"
						checked={selectedItems.includes("MON")}
						onChange={handleCheckboxChange}
					/>
					MON
				</label>
				<label>
					<input
						type="checkbox"
						value="TUE"
						checked={selectedItems.includes("TUE")}
						onChange={handleCheckboxChange}
					/>
					TUE
				</label>
				<label>
					<input
						type="checkbox"
						value="WED"
						checked={selectedItems.includes("WED")}
						onChange={handleCheckboxChange}
					/>
					WED
				</label>
				<label>
					<input
						type="checkbox"
						value="THU"
						checked={selectedItems.includes("THU")}
						onChange={handleCheckboxChange}
					/>
					THU
				</label>
				<label>
					<input
						type="checkbox"
						value="FRI"
						checked={selectedItems.includes("FRI")}
						onChange={handleCheckboxChange}
					/>
					FRI
				</label>
				<label>
					<input
						type="checkbox"
						value="SAT"
						checked={selectedItems.includes("SAT")}
						onChange={handleCheckboxChange}
					/>
					SAT
				</label>
				<label>
					<input
						type="checkbox"
						value="SUN"
						checked={selectedItems.includes("SUN")}
						onChange={handleCheckboxChange}
					/>
					SUN
				</label>
			</div>
		</div>
	);
};

export default DropdownMenu;
