import React, { useState } from "react";
import "./InputContainer.css";
import DropdownMenu from "./DropdownMenu";
import RegionDropdown from "./RegionDropDown";
import { useSelector } from "react-redux";

const InputContainer = (props) => {
	const [timeInputValue, setTimeInputValue] = useState("");
	const [daysInputValue, setDaysInputValue] = useState([]);
	const [typeInput, setTypeInput] = useState(props.instanceType);
	const { email } = useSelector(state => state.auth)
	const handleTimeInputChange = (event) => {
		setTimeInputValue(event.target.value);
		// console.log(inputValue);
	};

	const handleTypeInputChange = (type) => {
		setTypeInput(type);
	};

	const handleDaysInputChange = (selectedItems) => {
		// console.log(selectedItems);
		setDaysInputValue(selectedItems);
	};
	// console.log(props.isMachineSpecific);
	let setStart = props.setStart;
	return (
		<div>
			<div className="input-container">
				<p>{props.label}</p>
				{!props.enabled ? (
					<>
						<input
							type="time"
							className="input-field"
							value={timeInputValue}
							onChange={handleTimeInputChange}
						/>
						<DropdownMenu onChange={handleDaysInputChange} />{" "}
					</>
				) : (
					<div className="input-data">{props.placeholder}</div>
				)}
				{props.isAdmin || props.isMachineSpecific ? (
					<button
						className="button"
						onClick={() => {
							if (props.enabled) {
								props.onClick(
									props.instanceId,
									setStart,
									email,
									props.id,
									props.setLoading,
									props.fetchScheduleList
								);
							} else {
								props.onClick(
									timeInputValue,
									daysInputValue,
									props.instanceId,
									setStart,
									email,
									typeInput,
									props.id,
									props.setLoading,
									props.fetchScheduleList
								);
							}
						}}
					>
						{props.type}
					</button>
				) : (
					<></>
				)}
				{!props.enabled &&
				props.changeTypeOperationStatus &&
				props.isMachineSpecific &&
				props.setStart ? (
					<RegionDropdown
						selectedVal={typeInput}
						content={props.instanceTypes}
						setSelectedVal={setTypeInput}
						onSelect={(inputType) => {
							handleDaysInputChange(inputType);
						}}
					/>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default InputContainer;
