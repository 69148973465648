import React, { useEffect, useState } from "react";

const InstanceButton = ({ text, color, textCol, onClick, isEnabled }) => {
	const [textState, setTextState] = useState("Pending");
	const [textTailwindState, setTextTailwindState] = useState(
		"text-base p-1 text-black"
	);
	const [tailwindState, setTailwindState] = useState(
		"mt-2 mx-1 bg-zinc-300 rounded"
	);

	useEffect(() => {
		if (isEnabled) {
			const newState =
				color === "green-500"
					? `mt-2 mx-1 bg-green-500 rounded hover:scale-110 cursor-pointer`
					: color === "red-500"
					? `mt-2 mx-1 bg-red-500 rounded hover:scale-110 cursor-pointer`
					: `mt-2 mx-1 bg-zinc-300 rounded hover:scale-110 cursor-pointer`;
			setTextState(text);
			setTailwindState(newState);
			if (color === "zinc-300") {
				setTextTailwindState("text-base p-1 text-black");
			} else {
				setTextTailwindState("text-base p-1 text-white");
			}
		} else {
			setTextState("Pending");
			setTailwindState("mt-2 mx-1 bg-zinc-300 rounded");
			setTextTailwindState("text-base p-1 text-white");
		}
	}, []);

	const buttonStyle = {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		padding: "2px",
	};

	return (
		<div
			style={buttonStyle}
			className={tailwindState}
			onClick={isEnabled ? onClick : () => {}}
		>
			<p className={textTailwindState}>{textState}</p>
		</div>
	);
};

export default InstanceButton;
