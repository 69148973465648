import { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";
import { formatDataForPieChart } from "../utils";

const getTotalValue = (pieChartData, key) => {
	let totalValue = 0;
	for (let item of pieChartData) {
		totalValue += item[key];
	}
	return totalValue;
};

const PieChart = (props) => {
	// console.log('inside pie chart', props.data)
	const [rowData, setRowData] = useState(props.data);
	const [chartData, setChartData] = useState(formatDataForPieChart(rowData));
	const [overallSum, setOverallSum] = useState(100);

	
	useEffect(() => {
		setRowData(props.data)
	  }, [props])

	const handleLegendItemClick = (params) => {
		// const { data } = params;
		let sum = overallSum;
		if (params.enabled) {
			sum += rowData[params.itemId].netpnl;
		} else {
			sum -= rowData[params.itemId].netpnl;
		}
		setOverallSum(sum);
	};
	const chartOptions = {
		data: rowData,
		height:props.type === "live" ? 500 : 600,
		title: {
			text: props.title,
		},
		subtitle: {
			text: "in percentage",
		},
		legend: {
			maxWidth:500,
			listeners: {
				legendItemClick: (params) => {
					// console.log("params", params);
					handleLegendItemClick(params);
				}, // Handle legend item click event
			},
			item: {
				toggleSeriesVisible: true,
			},
		},
		series: [
			{
				// showInLegend: true,
				// visible: true,
				type: "pie",
				angleKey: "netpnl",
				calloutLabelKey: "category",
				tooltip: {
					renderer: (params) => {
						return {
							title: params.datum["category"],
							content: `${(
								(params.datum["netpnl"] / overallSum) *
								100
							).toFixed(2)}%`,
						};
					},
				},
			},
		],
	};

	return (
		<div class="self-center w-full mx-20">
			<AgChartsReact options={chartOptions} />
		</div>
	);
};

export default PieChart;
