import React from "react";

const Block = ({ title, val, color }) => {
  return (
    <div class="flex flex-col m-5 py-4 border-b-2 shadow-md justify-center">
      <p class="font-bold text-center text-md ml-3">{title}</p>
      <p class={`font-bold text-center ${color} text-md px-3`}>{val}</p>
    </div>
  );
};

export default Block;
