export const finYears = [
  "FY 2024",
  "FY 2023",
  "FY 2022",
  "FY 2021",
  "FY 2020",
  "FY 2019",
  "FY 2018",
  "FY 2017",
];
// "Custom Date"
export const finyears = ["2024", "2023", "2022", "2021", "2020", "2019"];
export const formats = ["Year wise", "Custom Date"];

export const months = [
  "all",
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const redValues = ["maxDrawdown", "mdd", "negativeDays", "cdd"];
export const greenValues = ["tv", "positiveDays"];

export const dateFilter = ["date", "mtmtv", "startDate", "endDate"];
export const textFilter = [
  "strategy",
  "subStrategy",
  "month",
  "client",
  "symbol",
  "assetClass",
];

export const numericRegex = /^[0-9\b]+$/;

export const multipliers = {
  USD: 1,
  INR: 82,
  BRL: 5.1,
};

export const currencyMultipliers = {
  CME: { USD: 1, INR: 82, BRL: 5 },
  MCX: { USD: 0.012, INR: 1, BRL: 0.061 },
  NSE: { USD: 0.012, INR: 1, BRL: 0.061 },
  NSE_INDEX: { USD: 0.012, INR: 1, BRL: 0.061 },
  NSE_OPTIONS: { USD: 0.012, INR: 1, BRL: 0.061 },
  B3: { USD: 0.2, INR: 16, BRL: 1 },
};

export const exchangeProperties = {
  CME: ["turnover"],
  MCX: ["turnover"],
  NSE: ["turnover"],
  NSE_INDEX: ["turnover"],
  NSE_OPTIONS: ["notionalTv", "premiumTv"],
  B3: ["turnover"],
};

export const headersEditable = ["date", "rolling", "days"];

export const strategyRelatedInfo = ["logic", "assetClass", "client"];

export const features = ["netpnl", "tv", "volatility", "netpnlEnv", "cdd"];

export const weightageInitialData = [
  {
    feature: "netpnl",
    weightage: 1,
  },
  {
    feature: "tv",
    weightage: 1,
  },
  {
    feature: "volatility",
    weightage: 1,
  },
  {
    feature: "cdd",
    weightage: 1,
  },
  {
    feature: "netpnlEnv",
    weightage: 1,
  },
];

export const logics = [
  "Netpnl : Decrease if last 750 days pnl sum < 0, otherwise no change",
  "Tv : Increase if avg tv pct of last 20 days rolling mean over last 500 days is > 80, decrease if it is < 20, otherwise no change",
  "Volatility : Increase if pct of abs move mean of last 20 days is > 50, Decrease if abs move mean of last 60 days is < 20, otherwise no change",
  "Cdd : Decrease if cdd based on number of days is >= 400, otherwise no change",
  "NetpnlEnv : Difference of 120 days pnl pct and 20 days abs move pct is calcuated and the values are summed over an entire week and this is done for current week and last week, Decrease if the sum of current week and last week is greater than 1.5, Increase if sum is less than -1 and 120 day sum pnl pct is greater than the previous day 120 day sum pnl pct, otherwise no change.",
];

export const daysToExpiry = ["0", "1", "2", "3", "4"];
