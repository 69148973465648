import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import {useDispatch} from "react-redux"
import React, { useEffect, useState } from "react";
import { Link, resolvePath, useNavigate } from "react-router-dom";
import { setEmail, setToken, setTokenExpiryTime} from "../redux/slices/credentials";

const Signup = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const responseMessage = async (response) => { 
    
    //console.log(response.credential)
    const res = await axios.post(`/api/getToken`, {
      userCred : response.credential,
    })
    
    localStorage.setItem("token", res.data.token);
    let currMilliSeconds = new Date().getTime()
    // console.log('currTime', currMilliSeconds)
    let expMilliSeconds = currMilliSeconds + 36000000
    // let expMilliSeconds = currMilliSeconds + 10000
    // console.log('exptime', expMilliSeconds)
    dispatch(setToken(res.data.token))
    dispatch(setEmail(res.data.email))
    dispatch(setTokenExpiryTime(expMilliSeconds))
    navigate('/live_data')

  };
  
  const errorMessage = (error) => {
    console.log("error", error);
  };

  return (
    <>
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden" >
      <div className="w-3xl p-10 m-auto bg-white rounded-md shadow-lg lg:max-w-3xl">
        <div class="flex flex-col">
          <h1 className="text-3xl font-bold text-center text-sky-700 underline">
            Welcome to 26 Miles Capital Dashboard
          </h1>
          <img class="mx-auto w-2/4" src={require("../assets/26milescapital_logo.jpg")} />
        </div>
        <div class="my-4 ">
          <GoogleOAuthProvider clientId="755704835366-gb7ksq4rg4tbae0ksvd4s7jb6bvkmhon.apps.googleusercontent.com">
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  </>
  );
};

export default Signup;

