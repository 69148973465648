import React from "react";

const Card = ({ title, content }) => {
  return (
    <div className="rounded-lg shadow-lg p-2 bg-white">
      <h2 className="text-sm font-semibold mb-1">{title}</h2>
      {content.map((item) => {
        return <p className="text-gray-700 text-xs my-1">{item}</p>;
      })}
    </div>
  );
};

export default Card;
