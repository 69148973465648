import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import authReducer from "./slices/credentials.js";
import tableDataReducer from "./slices/tableData.js";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    table: tableDataReducer,
  },
});
