import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  dateFilter,
  greenValues,
  months,
  numerical,
  redValues,
  strats,
  textFilter,
} from "../data";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import axios from "axios";
import LoaderComp from "./LoaderComp";
import { getHiddenValues } from "../utils";
import ColumnsDropdown from "./ColumnsDropdown";
import { BsInfoCircle } from "react-icons/bs";
import Card from "./Card";
//import TableRow from "./TableRow";

const DataTable = ({
  theadData,
  data,
  setData,
  year,
  selectedStr,
  filterChanges,
  month,
  filterAll,
  type,
  finYear,
  mdd,
  setMdd,
  date,
  date_2,
  strategies,
}) => {
  const gridRef = useRef();
  const [rowData, setRowData] = useState(data);
  const [flag, setFlag] = useState(false);

  const currencyFormatter = (params) => {
    return typeof params.value === Number
      ? params.value.toLocaleString()
      : Math.floor(params.value).toLocaleString();
  };
  const defaultFormatter = (params) => {
    return params.value;
  };

  useEffect(() => {
    setRowData(data);
  }, []);

  const [visibleCols, setVisibleCols] = useState([
    "strategy",
    "subStrategy",
    "allocation",
    "calmarRatio",
    "sharpeRatio",
    "roc",
    "startDate",
    "endDate",
    "netpnl",
    "mdd",
    "cdd",
    "month",
    "thrpnl",
    "date",
  ]);

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    setColumnDefs(
      theadData &&
        theadData.map((th, index) =>
          Object.assign({
            field: th,
            flex: 1,
            width: th === "strategy" && type === "bt" ? 250 : "default",
            hide: visibleCols.indexOf(th) === -1 ? true : false,
            pinned:
              th === "strategy" && selectedStr === "all" ? "left" : "default",
            editable: th === "allocation" ? true : false,
            cellEditor: "agNumberCellEditor",
            filter:
              dateFilter.indexOf(th) !== -1
                ? "agDateColumnFilter"
                : textFilter.indexOf(th) !== -1
                ? "agTextColumnFilter"
                : "agNumberColumnFilter",
            cellStyle: (params) => {
              if (th === "strategy" && type == "bt") {
                return { fontSize: 12 };
              }
              if (redValues.indexOf(th) !== -1) {
                return { color: "red", fontWeight: 500 };
              } else if (greenValues.indexOf(th) !== -1) {
                return { color: "green", fontWeight: 500 };
              } else {
                if (
                  (dateFilter.indexOf(th) === -1 || th === "mtmtv") &&
                  textFilter.indexOf(th) === -1
                ) {
                  if (parseFloat(params.value) > 0)
                    return { color: "green", fontWeight: 500 };
                  else return { color: "red", fontWeight: 500 };
                }
              }
            },
            valueFormatter:
              dateFilter.indexOf(th) === -1 &&
              textFilter.indexOf(th) === -1 &&
              th !== "calmarRatio" &&
              th !== "sharpeRatio" &&
              th !== "roc"
                ? currencyFormatter
                : defaultFormatter,
          })
        )
    );
  }, [visibleCols, theadData]);

  const [content, setContent] = useState(
    getHiddenValues(theadData, visibleCols)
  );

  const filterChange_live = (event) => {
    const filterData = event.api.rowModel.rowsToDisplay.map(
      (item) => item.data
    );
    console.log("filterData", filterData);
    let netpnl = 0;
    let tv = 0;
    if (!(selectedStr === "all") || !(month === "all")) {
      filterData.forEach((item) => {
        netpnl += item.netpnl;
        tv += item.tv;
      });
      filterChanges(netpnl, tv);
    } else if (selectedStr === "all" && month === "all") {
      filterData.forEach((item) => {
        strategies.forEach((strat) => {
          if (strat !== "all" && item.month !== "Total Netpnl") {
            netpnl += item[strat];
            console.log("netpnl", strat, netpnl);
          }
        });
      });
      console.log("netpnl", netpnl);
      filterAll(netpnl);
    }
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
  }));

  return (
    <>
      {flag ? (
        <div class="flex justify-center items-center my-4">
          <LoaderComp />
        </div>
      ) : (
        <>
          <div class="flex justify-between">
          {month === "all" && selectedStr === "all" ? null : (
              <div class="self-end mr-4">
                <ColumnsDropdown
                  visibleCols={visibleCols}
                  setVisibleCols={setVisibleCols}
                  content={content}
                  setContent={setContent}
                />
              </div>
            )}
            <div className="mt-2">
              <Card
                title={"Conversion Rate"}
                content={["USD->INR = 83", "BRL->INR=16"]}
              />
            </div>
           
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              width: "100%",
              height: 500,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {" "}
            {type === "bt" ? (
              <>
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  animateRows={true}
                  // onFilterChanged={filterChange}
                  // onCellValueChanged={filterChange}
                  suppressScrollOnNewData={true}
                />
              </>
            ) : (
              <AgGridReact
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                // onFilterChanged={filterChange_live}
                // onCellValueChanged={filterChange_live}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};
export default DataTable;
