import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";

const CalendarComp = ({ date, setDate }) => {
  const [open, setOpen] = useState(false);

  const ref = useRef();
  // console.log("calendar open", open)
  useEffect(() => {
		const handleClickOutside = (event) => {
			if (!ref?.current?.contains(event.target)) {
				setOpen(false);
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
	}, [ref]);

  const handleOpen = () => {
    setOpen(!open);
  };
  return (
    <div class=" ml-6 relative select-none" ref={ref}>
      <div
        class="p-3 bg-zinc-200 shadow-md font-bold flex items-center text-slate-500 justify-between cursor-pointer z-50"
        onClick={handleOpen}
      >
        {date.getDate() +
          "/" +
          (parseInt(date.getMonth()) + 1) +
          "/" +
          date.getFullYear()}
      </div>
      {open ? (
        <div
          class="absolute top-full left-0 py-3 bg-white font-normal text-slate-500 shadow-md w-fit z-50"
        >
          <Calendar onChange={setDate} value={date} onClickDay={handleOpen} />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default CalendarComp;
