import React from "react";

const InputCheckbox = ({ refer, val, handleChange, content, title }) => {
  return (
    <label class="flex mx-2 justify-between">
      <p class="text-sm ml-4">
        {title === "Substrategy" && val != "Select All" && val != "Clear All"
          ? val.substring(0, val.lastIndexOf("_"))
          : val}
      </p>
      <input
        ref={refer}
        value={val}
        type="checkbox"
        checked={content.includes(val)}
        onChange={handleChange}
      />
    </label>
  );
};

export default InputCheckbox;
