import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../utils";

const ToggleSwitch = ({
  option1,
  option2,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <div className="flex items-center">
      {/* Live option */}
      <div
        onClick={() => {
          setSelectedOption(option1);
        }}
        class={`px-4 py-2 focus:outline-none cursor-pointer ${
          selectedOption === option1
            ? "bg-blue-500 text-white font-bold scale-110"
            : "bg-gray-300 font-light"
        }`}
      >
        {capitalize(option1)}
      </div>

      {/* Backtesting option */}
      <div
        onClick={() => {
          setSelectedOption(option2);
        }}
        class={`px-4 py-2 ml-2 focus:outline-none cursor-pointer ${
          selectedOption === option2
            ? "bg-blue-500 text-white font-bold scale-110"
            : "bg-gray-300 font-light"
        }`}
      >
        <p>{capitalize(option2)}</p>
      </div>
    </div>
  );
};

export default ToggleSwitch;
