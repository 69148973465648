import InstanceButton from "../InstanceButton";
import InputContainer from "./InputContainer";
import RegionDropdown from "./RegionDropDown";
import LoaderComp from "../LoaderComp";
import InstanceRow from "./InstanceRow";
import { useSelector } from "react-redux";
const EC2Table = (props) => {
  // State to hold the instance data
  const instanceTypeHashMap = {
    nano: 0,
    micro: 1,
    small: 2,
    medium: 3,
    large: 4,
    xlarge: 5,
    "2xlarge": 6,
    "4xlarge": 7,
    "8xlarge": 8,
    "16xlarge": 9,
  };

  const { email } = useSelector(state => state.auth);
  let instanceData = props.instanceData;
  let removeIgnoreInstance = props.removeIgnoreInstance;
  let addIgnoreInstance = props.addIgnoreInstance;
  let enableTimeTriggerSpecific = props.enableTimeTriggerSpecific;
  let disableTimeTriggerSpecific = props.disableTimeTriggerSpecific;
  const instanceTypes = props.instanceTypes;
  const tableStyle = {
    border: "1px solid #000", // Add a border to the whole table
    borderCollapse: "collapse", // Collapse borders into a single border
    width: "100%",
    textAlign: "center", // Center-align the cell content
    align: "center",
  };

  const cellStyle = {
    border: "1px solid #000", // Add borders to table cells
    padding: "4px", // Add some padding for better spacing
    textAlign: "center", // Center-align the cell content
    align: "center",
  };

  // Render the table
  return (
    <div>
      <table style={tableStyle}>
        <thead>
          <tr>
            <th style={cellStyle}>Instance Name</th>
            <th style={cellStyle}>Private IP</th>
            <th style={cellStyle}>Instance ID</th>
            <th style={cellStyle}>Instance Type</th>
            <th style={cellStyle}>Instance Status</th>
            <th style={cellStyle}>Action</th>
            <th style={cellStyle}>Group Scheduler</th>
            <th style={cellStyle}>Stop Scheduler</th>
            <th style={cellStyle}>Start Scheduler</th>
            <th style={cellStyle}>Scale</th>
          </tr>
        </thead>
        <tbody>
          {instanceData.map((instance) => (
            <InstanceRow
              instance={instance}
              removeIgnoreInstance={removeIgnoreInstance}
              addIgnoreInstance={addIgnoreInstance}
              enableTimeTriggerSpecific={enableTimeTriggerSpecific}
              disableTimeTriggerSpecific={disableTimeTriggerSpecific}
              showAlert={props.showAlert}
              instanceTypes={instanceTypes}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EC2Table;
