import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { dateFilter, strategyRelatedInfo, textFilter } from "../data";
import ToggleSwitch from "./ToggleSwitch";

const AllocationTable = ({ data, headData }) => {
  const [rowData, setRowData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [selectedOption, setSelectedOption] = useState("text");

  useEffect(() => {
    setRowData(data);
    setOriginalData(data);
  }, [data]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      cellStyle: () => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "start",
      }),
    }),
    []
  );

  const cellRendererFunction = (params) => {
    const value = params.value;

    // console.log("params", params);

    if (value > 0)
      return (
        <p class="text-green-500 font-semibold text-xs">
          {selectedOption === "text" ? "Increase" : params.value}
        </p>
      );
    if (value === 0)
      return (
        <p class="text-xs">
          {selectedOption === "text" ? "No Change" : params.value}
        </p>
      );
    if (value < 0)
      return (
        <p class="text-red-500 font-semibold text-xs">
          {selectedOption === "text" ? "Decrease" : params.value}
        </p>
      );
  };

  const columnDefs = useMemo(() => {
    return (
      headData &&
      headData.map((head, index) =>
        Object.assign({
          field: head,
          flex: 1,
          width: 200,
          filter:
            dateFilter.indexOf(head) !== -1
              ? "agDateColumnFilter"
              : textFilter.indexOf(head) !== -1
              ? "agTextColumnFilter"
              : "agNumberColumnFilter",
          cellRenderer: strategyRelatedInfo.includes(head)
            ? (params) => <p class="text-xs">{params.value}</p>
            : (params) => cellRendererFunction(params),
        })
      )
    );
  });
  return (
    <>
      <div
        className="ag-theme-alpine"
        style={{
          width: "100%",
          height: 500,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          // onFilterChanged={filterChange}
          // onCellValueChanged={filterChange}
          suppressScrollOnNewData={true}
        />
        <div class="mx-auto flex mb-10 mt-3 ">
          <ToggleSwitch
            option1={"text"}
            option2={"numeric"}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
      </div>
    </>
  );
};

export default AllocationTable;
