import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data : []
}

export const tableDataSlice = createSlice({
    name : 'table',
    initialState,
    reducers : {
        updateTableData : (state, action) => {
            // console.log('action', action)
           state.data = action.payload
        }
    }
})

export const {updateTableData} = tableDataSlice.actions

export default tableDataSlice.reducer