import React, { useEffect, useRef, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axios from "axios";
import { options, finyears, months, formats } from "../data";
import CalendarComp from "../components/Calendar";
import "react-calendar/dist/Calendar.css";
import DataTable from "../components/DataTable";
import { checkYearsContinuity, convertDate } from "../utils";
import Block from "../components/Block";
import DropdownHelper from "../components/Helper";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import LoaderComp from "../components/LoaderComp";
import LineChart from "../components/Linechart";
import FilterDropdown from "../components/FilterDropdown";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import PieChart from "../components/PieChart";
import GroupedTable_bt from "../components/GroupedTable_bt";
import SearchableDropdown from "../components/SearchableDropdown";
import { useSelector } from "react-redux";
import ToggleSwitch from "../components/ToggleSwitch";
import HoverDropdown from "../components/HoverDropdown";
import Card from "../components/Card";
import SessionExpiredDialog from "../components/SessionExpiredDialog";
import { downloadDataBtApi } from "../utils/api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

//let pageSize = 15;
function Dashboard_bt() {
  //const [currentPage, setCurrentPage] = useState(1);

  const { token, tokenExpiryTime } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mtv, setMtv] = useState();

  const [isLanding, setIsLanding] = useState(true);
  const [landingData, setLandingData] = useState([]);
  const [selectedStrats, setSelectedStrats] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedFinyears, setSelectedFinyears] = useState([]);
  const [date, setDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );

  const [tableData, setTableData] = useState([]);
  const [strategyWiseData, setStrategyWiseData] = useState([]);

  const [chartData, setChartData] = useState([]);
  const [date_2, setDate_2] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState("backtesting");

  const [val1, setVal1] = useState();
  const [val2, setVal2] = useState();
  const [val3, setVal3] = useState();
  const [format, setFormat] = useState([]);
  const [profitPieData, setProfitPieData] = useState([]);
  const [lossPieData, setLossPieData] = useState([]);
  const [selectedFormat, setSelectedFormat] = useState("Year wise");
  const [isOpen, setIsOpen] = useState(false);
  const [strats, setStrats] = useState([]);
  const [allStrategies, setAllStrategies] = useState([]);
  const [years, setYears] = useState(finyears);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [flag, setFlag] = useState();
  const [reportData, setReportData] = useState([]);
  const [yearStrategyWiseMap, setYearStrategyWiseMap] = useState({});
  const backupOverallData = useRef(null);
  const [overallMetrices, setOverallMetrices] = useState({
    netpnl: 0,
    tv: 0,
    mdd: 0,
    posDays: 0,
    negDays: 0,
    mddStartDate: "",
    mddEndDate: "",
    mddDays: 0,
    cddDays: 0,
    cdd: 0,
    calmarRatio: 0,
    sharpeRatio: 0,
    worstPnl: 0,
    bestPnl: 0,
    meanPnl: 0,
    annualisedPnl: 0,
    roc: 0,
    chartData: [],
  });

  useEffect(() => {
    if (selectedOption === "live") navigate("/live_data");
  }, [selectedOption]);

  const headers = {
    Authorization: `Bearer ${
      token === null ? localStorage.getItem("token") : token
    }`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };
  const getStrategies = async () => {
    await fetchStrategies();
  };
  useEffect(() => {
    getStrategies();
  }, []);
  useEffect(() => {
    setTableData([]);
    setFlag(false);
  }, [selectedFormat, selectedStrats, selectedFinyears]);

  const fetchReportData = async () => {
    try {
      const response = await axios.get("api/bt/downloadReport", { headers });

      setReportData(response.data);

      //download report data
      const blob = new Blob([response.data], {
        type: "application/json",
      });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "backtestingReport.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.log("error happened", err);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
  };

  const fetchLandingData = async () => {
    setLoading(true);
    // console.log(isLoading);

    let responseData;
    let pieProfitData = [];
    let pieLossData = [];
    let rowsArr = [];
    let overallProfit = 0;
    let overallLoss = 0;
    try {
      responseData = await axios.get("/api/bt/getStrategyData", {
        headers,
      });
      // console.log("res", responseData);
      setAllStrategies(responseData.data.strategies);
      // setSelectedStrats();
      const newValues = ["Select All", "Clear All"];

      if (strats.indexOf("Select All") === -1) {
        setStrats([...newValues, ...responseData.data.strategies]);
        setSelectedStrats(responseData.data.strategies);
      }
      if (years.indexOf("Select All") === -1) {
        setYears([...newValues, ...years]);
        setSelectedFinyears(["2024"]);
        // setSelectedYears(strats);
      }

      const strategies = responseData.data.strategies;

      const body = {
        strats: strategies,
      };
      responseData = await axios.post("/api/bt/getLandingData", body, {
        headers,
      });
      console.log("response", responseData);
      if (responseData.data.strategywiseData.length === 0) {
        throw new Error("No data");
      }
      // console.log("responseData", responseData);
      responseData.data.strategywiseData.forEach((item) => {
        const category = `${item.strategy}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netpnl = parseInt(item.netpnl);
        const obj = {
          category,
          netpnl: item.netpnl,
        };
        // pieData.push(obj);
        if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netpnl;
        } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
          obj.netpnl = -obj.netpnl;
          pieLossData.push(obj);
          overallLoss += obj.netpnl;
        }
        // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
    // pieProfitData.length > 0 &&
    //   pieProfitData.forEach((item) => {
    //     item.netPnl = (item.netPnl / overallProfit) * 100;
    //   });
    // pieLossData.length > 0 &&
    //   pieLossData.forEach((item) => {
    //     item.netPnl = (item.netPnl / overallLoss) * 100;
    //   });

    responseData.data.strategywiseData.map((d) => rowsArr.push(Object.keys(d)));
    pieProfitData.length > 0 &&
      pieProfitData.forEach((item) => {
        item.netpnl = (item.netpnl / overallProfit) * 100;
      });
    pieLossData.length > 0 &&
      pieLossData.forEach((item) => {
        item.netpnl = (item.netpnl / overallLoss) * 100;
      });
    let chartFormattedData = [];
    responseData.data.overallData.chartData.forEach((item) => {
      const chartObj = {
        date: convertDate(item.date),
        netPnl: item.netPnl,
      };
      chartFormattedData.push(chartObj);
    });

    setLandingData(responseData.data.strategywiseData);
    setProfitPieData(pieProfitData);
    setLossPieData(pieLossData);
    setColumns(rowsArr[0]);
    setOverallMetrices({
      netpnl: responseData.data.overallData.overallNetPnl,
      tv: responseData.data.overallData.overallTv,
      calmarRatio: responseData.data.overallData.overallCalmarRatio,
      sharpeRatio: responseData.data.overallData.overallSharpeRatio,
      roc: responseData.data.overallData.overallRoc,
      mdd: responseData.data.overallData.overallMdd,
      posDays: responseData.data.overallData.overallPositiveDays,
      negDays: responseData.data.overallData.overallNegativeDays,
      chartData: chartFormattedData,
      mddStartDate: responseData.data.overallData.mddStartDate,
      mddEndDate: responseData.data.overallData.mddEndDate,
      mddDays: responseData.data.overallData.overallMddDays,
      cddDays: responseData.data.overallData.overallCddDays,
      cdd: responseData.data.overallData.overallCdd,
      worstPnl: responseData.data.overallData.overallWorstPnl,
      bestPnl: responseData.data.overallData.overallBestPnl,
      meanPnl: responseData.data.overallData.overallMeanPnl,
      annualisedPnl: responseData.data.overallData.overallAnnualisedPnl,
    });
    if (!backupOverallData.current) {
      backupOverallData.current = responseData.data.overallData;
    }

    setLoading(false);
    // console.log("here");
  };
  const fetchStrategies = async () => {
    try {
      let responseData = await axios.get("/api/bt/getStrategyData", {
        headers,
      });
      setAllStrategies(responseData.data.strategyData);
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
  };

  const fetchYearStrategyWiseData = async () => {
    let responseData;
    let yearStrategyMap = {};
    let years = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }

    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      date1: selectedFormat === "Custom Date" ? date : null,
      date2: selectedFormat === "Custom Date" ? date_2 : null,
    };
    // console.log("strategy", selectedStrats);
    try {
      responseData = await axios.post(
        "/api/bt/getYearStrategyWiseData",

        body,

        {
          headers,
        }
      );
      // responseData.data.tableData.forEach((item) => {
      //   item["allocation"] = 1;
      // });

      setTableData(responseData.data.tableData);
      console.log("res3", responseData);
      responseData.data.tableData.forEach((item) => {
        yearStrategyMap[`${item.strategy} ${item.year}`] = {
          netpnl: item.netpnl,
          tv: item.tv,
          mdd: item.mdd,
          worstPnl: item.worstPnl,
          bestPnl: item.bestPnl,
        };
      });
    } catch (err) {
      setLoading(false);
      showErrorSnackbar(err.message);
      return;
    }
    // console.log("yearRegionMap", yearRegionMap);
    // console.log("yearStrategy", yearStrategyMap)
    setYearStrategyWiseMap(yearStrategyMap);
  };

  const fetchOverallData = async () => {
    let years = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }
    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      date1: selectedFormat === "Custom Date" ? date : null,
      date2: selectedFormat === "Custom Date" ? date_2 : null,
    };
    try {
      let responseData = await axios.post("/api/bt/getOverallData", body, {
        headers,
      });
      console.log("res1", responseData);
      let chartFormattedData = [];
      responseData.data.overallData.chartData.forEach((item) => {
        const chartObj = {
          date: convertDate(item.date),
          netPnl: item.netPnl,
        };
        chartFormattedData.push(chartObj);
      });
      setOverallMetrices({
        ...overallMetrices,
        netpnl: responseData.data.overallData.overallNetPnl,
        tv: responseData.data.overallData.overallTv,
        calmarRatio: responseData.data.overallData.overallCalmarRatio,
        sharpeRatio: responseData.data.overallData.overallSharpeRatio,
        roc: responseData.data.overallData.overallRoc,
        mdd: responseData.data.overallData.overallMdd,
        cdd: responseData.data.overallData.overallCdd,
        mddDays: responseData.data.overallData.overallMddDays,
        cddDays: responseData.data.overallData.overallCddDays,
        posDays: responseData.data.overallData.overallPositiveDays,
        negDays: responseData.data.overallData.overallNegativeDays,
        chartData: chartFormattedData,
        mddStartDate: responseData.data.overallData.mddStartDate,
        mddEndDate: responseData.data.overallData.mddEndDate,
        worstPnl: responseData.data.overallData.overallWorstPnl,
        bestPnl: responseData.data.overallData.overallBestPnl,
        meanPnl: responseData.data.overallData.overallMeanPnl,
        annualisedPnl: responseData.data.overallData.overallAnnualisedPnl,
      });
      // setColumns(rowsArr[0]);
      setChartData(chartFormattedData);
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
    }
  };
  const fetchStrategyWiseData = async () => {
    let pieProfitData = [];
    let pieLossData = [];
    let overallLoss = 0;
    let overallProfit = 0;
    let years = [];
    let strategyWise = [];
    if (selectedFormat === "Custom Date") {
      let month1 = parseInt(date.getMonth()) + 1;
      let year1 =
        month1 > 3
          ? parseInt(date.getFullYear())
          : parseInt(date.getFullYear()) - 1;
      let month2 = parseInt(date_2.getMonth()) + 1;
      let year2 =
        month2 > 3
          ? parseInt(date_2.getFullYear())
          : parseInt(date_2.getFullYear()) - 1;
      while (year1 <= year2) {
        years.push(year1.toString());
        year1++;
      }
    }
    const body = {
      years: selectedFormat === "Custom Date" ? years : selectedFinyears,
      strategies: selectedStrats,
      date1: selectedFormat === "Custom Date" ? date : null,
      date2: selectedFormat === "Custom Date" ? date_2 : null,
    };
    try {
      let responseData = await axios.post("/api/bt/getStrategyWiseData", body, {
        headers,
      });
      console.log("res2", responseData);
      responseData.data.strategywiseData.forEach((item) => {
        const category = `${item.strategy}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netpnl = parseInt(item.netpnl);
        const obj = {
          category,
          netpnl: item.netpnl,
        };
        strategyWise.push(item);
        // pieData.push(obj);
        if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netpnl;
        } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
          obj.netpnl = -obj.netpnl;
          pieLossData.push(obj);
          overallLoss += obj.netpnl;
        }
        // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
      pieProfitData.length > 0 &&
        pieProfitData.forEach((item) => {
          item.netpnl = (item.netpnl / overallProfit) * 100;
        });
      pieLossData.length > 0 &&
        pieLossData.forEach((item) => {
          item.netpnl = (item.netpnl / overallLoss) * 100;
        });

      setProfitPieData(pieProfitData);
      setLossPieData(pieLossData);
      // console.log('strategy wise', strategyWise)
      setStrategyWiseData(strategyWise);
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
  };

  const fetchTableData = async () => {
    setLoading(true);
    setFlag(true);

    try {
      if (selectedStrats.length === 0 || selectedFinyears.length === 0) {
        let message = "Please select";
        if (selectedFinyears.length === 0) message = `${message} years`;
        if (selectedStrats.length === 0) message = `${message} strategies`;

        throw new Error(`${message} from dropdowns above!`);
      }

      const response1 = fetchOverallData();
      const response2 = fetchStrategyWiseData();
      const response3 = fetchYearStrategyWiseData();
      const [res1, res2, res3] = await Promise.all([
        response1,
        response2,
        response3,
      ]);
    } catch (err) {
      console.log("error happened", err);
      setLoading(false);

      showErrorSnackbar(err.message);
    }

    setIsLanding(false);
    setLoading(false);
  };
  const filterChanges = (val1, val2) => {
    setVal1(val1);
    setVal3(val2);
    if (val2) setMtv(val1 / val2);
    else setMtv(0);
  };

  const filterAll = (val1) => {
    setVal1(val1);
    if (val3) setMtv(val1 / val3);
    else setMtv(0);
  };

  const changeOpen = (open) => {
    setIsOpen(open);
  };

  const downloadSummary = (data) => {
    const summaryData =
      Object.keys(data[0]).join(",") +
      "\n" +
      data.map((obj) => Object.values(obj).join(",")).join("\n");
    // console.log("summary", summaryData)

    const blob = new Blob([summaryData], {
      type: "application/json",
    });

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "summary.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Donwloading daily report for the particular case
  const downloadData = async (format, fileName) => {
    try {
      const downloadResponse = downloadDataBtApi(
        isLanding,
        strats,
        selectedStrats,
        selectedFinyears,
        headers,
        format,
        fileName
      );

      if (downloadResponse.err === null) throw downloadResponse.err;
    } catch (err) {
      // emailer(err.message);
      return showErrorSnackbar(err.message);
    }
  };

  // console.log('chartttt', overallMetrices.chartData)

  useEffect(() => {
    fetchLandingData();
  }, []);
  // console.log('allStrat', allStrategies);

  return (
    <div>
      <Header>
        <div
          onClick={() => {
            let pieProfitData = [];
            let pieLossData = [];
            let overallProfit = 0;
            let overallLoss = 0;
            let rowsArr = [];

            landingData.forEach((item) => {
              const category = `${item.strategy}`;
              // if (item.netPnl === "N/A") item.netPnl = 0;
              // overallNetPnl += item.netPnl;
              item.netpnl = parseInt(item.netpnl);
              const obj = {
                category,
                netpnl: item.netpnl,
              };
              // pieData.push(obj);
              if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
                pieProfitData.push(obj);
                overallProfit += obj.netpnl;
              } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
                obj.netpnl = -obj.netpnl;
                pieLossData.push(obj);
                overallLoss += obj.netpnl;
              }
              // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
            });
            pieProfitData.length > 0 &&
              pieProfitData.forEach((item) => {
                item.netpnl = (item.netpnl / overallProfit) * 100;
              });
            pieLossData.length > 0 &&
              pieLossData.forEach((item) => {
                item.netpnl = (item.netpnl / overallLoss) * 100;
              });
            landingData.map((d) => rowsArr.push(Object.keys(d)));
            let chartFormattedData = [];

            setSelectedFormat("Year wise");
            setSelectedFinyears(["2024"]);
            setSelectedStrats(allStrategies);

            // setVal2(backupOverallData.current.overallThrPnl);
            if (backupOverallData.current) {
              backupOverallData.current.chartData.forEach((item) => {
                const chartObj = {
                  date: convertDate(item.date),
                  netPnl: item.netPnl,
                };
                chartFormattedData.push(chartObj);
              });
              setOverallMetrices({
                netpnl: backupOverallData.current.overallNetPnl,
                tv: backupOverallData.current.overallTv,
                mdd: backupOverallData.current.overallMdd,
                calmarRatio: backupOverallData.current.overallCalmarRatio,
                sharpeRatio: backupOverallData.current.overallSharpeRatio,
                roc: backupOverallData.current.overallRoc,
                posDays: backupOverallData.current.overallPositiveDays,
                negDays: backupOverallData.current.overallNegativeDays,
                mddStartDate: backupOverallData.current.mddStartDate,
                mddEndDate: backupOverallData.current.mddEndDate,
                cddDays: backupOverallData.current.overallCddDays,
                mddDays: backupOverallData.current.overallMddDays,
                cdd: backupOverallData.current.overallCdd,
                worstPnl: backupOverallData.current.overallWorstPnl,
                bestPnl: backupOverallData.current.overallBestPnl,
                meanPnl: backupOverallData.current.overallMeanPnl,
                annualisedPnl: backupOverallData.current.overallAnnualisedPnl,
                chartData: chartFormattedData,
              });
            }
            setProfitPieData(pieProfitData);
            setLossPieData(pieLossData);
            setColumns(rowsArr[0]);
            setIsLanding(true);
          }}
        >
          {" "}
          <HoverDropdown
            title={"Home"}
            content={{
              Home: () => {
                navigate("/live_data");
              },
              Market: () => {
                navigate("/market");
              },
              Instances: () => {
                navigate("/instances");
              },
              Allocation: () => {
                navigate("/allocation");
              },
            }}
            icon={false}
          />
        </div>
        <HoverDropdown
          title={"Downloads"}
          content={{
            Summary: downloadSummary,
            "Monthly Report": () => downloadData("month", "monthlyReport"),
            "Daily Report": () => downloadData("daily", "dailyReport"),
            "Raw Report": () => downloadData("raw", "rawReport"),
            "Backtesting Report": fetchReportData,
          }}
          data={isLanding ? landingData : tableData}
          icon={true}
        />
      </Header>
      <div class="flex justify-center mt-3">
        <ToggleSwitch
          option1={"live"}
          option2={"backtesting"}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>
      {new Date().getTime() > tokenExpiryTime ? (
        <SessionExpiredDialog
          expired={new Date().getTime() > tokenExpiryTime}
        />
      ) : (
        <>
          <div class="flex w-full my-3 ml-3">
            <div class="w-1/6">
              <DropdownHelper
                selectedVal={selectedFormat}
                setSelectedVal={setSelectedFormat}
                content={["Year wise"]}
                title={"Select Format"}
              />
            </div>
            {selectedFormat === "Year wise" ? (
              <div class="w-1/6">
                <FilterDropdown
                  selectedVals={selectedFinyears}
                  setSelectedVals={setSelectedFinyears}
                  content={years}
                  title={"Financial Years"}
                />{" "}
              </div>
            ) : (
              <>
                <div class="mx-3 w-fit">
                  <CalendarComp date={date} setDate={setDate} />
                </div>
                <div class="mx-3 w-fit">
                  <CalendarComp date={date_2} setDate={setDate_2} />
                </div>
              </>
            )}

            <div class="w-1/4">
              <SearchableDropdown
                selectedVals={selectedStrats}
                setSelectedVals={setSelectedStrats}
                content={strats}
                title={"Strategy"}
              />
            </div>
          </div>

          <div
            onClick={fetchTableData}
            class="mt-4 flex justify-center items-center bg-cyan-700 rounded mx-auto shadow-lg h-fit hover:scale-110 w-fit cursor-pointer"
          >
            <p class="text-white text-lg font-bold p-3 font-serif">Search</p>
          </div>

          {loading ? (
            <div class="flex justify-center items-center mt-3">
              <LoaderComp />
            </div>
          ) : (
            <>
              {isLanding ? (
                <div class="flex flex-col w-full mx-auto h-fit">
                  {landingData.length ? (
                    <>
                      <div class="mt-1 flex flex-col mx-auto w-5/6">
                        {/* <p class="font-bold self-center">FY:2024, All strategies</p> */}
                        <DataTable
                          data={landingData}
                          theadData={columns}
                          type={"bt"}
                        />

                        <div class="grid grid-cols-5 gap-4 mt-6 sm:grid-grid-cols-1 ">
                          <Block
                            title={"Netpnl"}
                            val={parseFloat(
                              overallMetrices.netpnl
                            ).toLocaleString()}
                            color={
                              overallMetrices.netpnl > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />

                          <Block
                            title={"TV"}
                            val={parseFloat(
                              overallMetrices.tv
                            ).toLocaleString()}
                            color={
                              overallMetrices.tv > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          <Block
                            title={"MDD"}
                            val={parseFloat(
                              overallMetrices.mdd
                            ).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"MDD Days"}
                            val={
                              overallMetrices.mdd === 0
                                ? 0
                                : parseFloat(
                                    overallMetrices.mddDays
                                  ).toLocaleString()
                            }
                            color={"text-black-500"}
                          />
                          <Block
                            title={"CDD"}
                            val={parseFloat(
                              overallMetrices.cdd
                            ).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"CDD days"}
                            val={parseFloat(
                              overallMetrices.cddDays
                            ).toLocaleString()}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"MDD Period"}
                            val={
                              overallMetrices.mdd === 0
                                ? "N/A"
                                : convertDate(overallMetrices.mddStartDate) +
                                  "-" +
                                  convertDate(overallMetrices.mddEndDate)
                            }
                            color={"text-black-500"}
                          />
                          <Block
                            title={"Calmar Ratio"}
                            val={
                              overallMetrices.mdd === 0
                                ? "N/A"
                                : parseFloat(
                                    overallMetrices.calmarRatio
                                  ).toLocaleString()
                            }
                            color={
                              overallMetrices.calmarRatio > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          <Block
                            title={"Sharpe Ratio"}
                            val={parseFloat(
                              overallMetrices.sharpeRatio
                            ).toLocaleString()}
                            color={
                              overallMetrices.sharpeRatio > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          <Block
                            title={"Annualised Pnl"}
                            val={parseFloat(
                              overallMetrices.annualisedPnl
                            ).toLocaleString()}
                            color={
                              overallMetrices.annualisedPnl > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          <Block
                            title={"Positve days"}
                            val={overallMetrices.posDays}
                            color={"text-green-500"}
                          />

                          <Block
                            title={"Negative days"}
                            val={overallMetrices.negDays}
                            color={"text-red-500"}
                          />
                          <Block
                            title={"Best Day Pnl"}
                            val={parseFloat(
                              overallMetrices.bestPnl
                            ).toLocaleString()}
                            color={"text-green-500"}
                          />
                          <Block
                            title={"Worst Day Pnl"}
                            val={parseFloat(
                              overallMetrices.worstPnl
                            ).toLocaleString()}
                            color={"text-red-500"}
                          />

                          <Block
                            title={"Average Pnl"}
                            val={parseFloat(
                              overallMetrices.meanPnl
                            ).toLocaleString()}
                            color={
                              overallMetrices.meanPnl > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                          <Block
                            title={"ROC"}
                            val={parseFloat(overallMetrices.roc)}
                            color={
                              overallMetrices.roc > 0
                                ? "text-green-500"
                                : "text-red-500"
                            }
                          />
                        </div>
                      </div>
                      <div class="md:flex xs:flex-col mx-auto mt-8 w-full">
                        {profitPieData.length > 0 ? (
                          <PieChart
                            type={"bt"}
                            data={profitPieData}
                            title={"Strategy wise profit in 2024"}
                          />
                        ) : (
                          <></>
                        )}
                        {lossPieData.length > 0 ? (
                          <PieChart
                            type={"bt"}
                            data={lossPieData}
                            title={"Strategy wise loss in 2024"}
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                      {overallMetrices.chartData.length > 0 ? (
                        <div class="mb-9 mx-auto w-5/6">
                          <LineChart
                            data={overallMetrices.chartData}
                            yKey={"netPnl"}
                            text={"Netpnl vs Date"}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <p class="font-bold text-center mt-6 text-lg">
                      No data exists!{" "}
                    </p>
                  )}
                </div>
              ) : (
                <>
                  {tableData.length ? (
                    <div class="mt-6 flex flex-col mx-auto w-5/6">
                      <div class="flex flex-col">
                        <div class={`flex flex-col w-full mx-auto h-fit`}>
                          {selectedFormat === "Custom Date" ? null : ( // /> //   theadData={columns} //   data={tableData} // <DataTable
                            <>
                              <div class="flex justify-end">
                                <div className="mt-2 w-fit">
                                  <Card
                                    title={"Conversion Rate"}
                                    content={["USD->INR = 83", "BRL->INR=16"]}
                                  />
                                </div>
                              </div>
                              <GroupedTable_bt
                                tableData={tableData}
                                setTableData={setTableData}
                                yearStrategyWiseMap={yearStrategyWiseMap}
                                profitPieData={profitPieData}
                                lossPieData={lossPieData}
                                setProfitPieData={setProfitPieData}
                                setLossPieData={setLossPieData}
                                setOverallMetrices={setOverallMetrices}
                                overallMetrices={overallMetrices}
                                setUpdateLoader={setUpdateLoader}
                                headers={headers}
                              />
                            </>
                          )}
                        </div>
                        {updateLoader ? (
                          <div class="flex justify-center items-center mt-3">
                            <LoaderComp />
                          </div>
                        ) : (
                          <div class="w-full">
                            <>
                              <div class="grid grid-cols-5 gap-4 mt-6 sm:grid-grid-cols-1 ">
                                <Block
                                  title={"Netpnl"}
                                  val={parseFloat(
                                    overallMetrices.netpnl
                                  ).toLocaleString()}
                                  color={
                                    overallMetrices.netpnl > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />

                                <Block
                                  title={"TV"}
                                  val={parseFloat(
                                    overallMetrices.tv
                                  ).toLocaleString()}
                                  color={
                                    overallMetrices.tv > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                                <Block
                                  title={"MDD"}
                                  val={
                                    checkYearsContinuity(selectedFinyears)
                                      ? parseFloat(
                                          overallMetrices.mdd
                                        ).toLocaleString()
                                      : "N/A"
                                  }
                                  color={"text-red-500"}
                                />
                                <Block
                                  title={"MDD days"}
                                  val={
                                    overallMetrices.mdd === 0 ||
                                    (selectedFormat === "Year wise" &&
                                      !checkYearsContinuity(selectedFinyears))
                                      ? "N/A"
                                      : parseFloat(
                                          overallMetrices.mddDays
                                        ).toLocaleString()
                                  }
                                  color={"text-red-500"}
                                />
                                <Block
                                  title={"CDD"}
                                  val={
                                    !checkYearsContinuity(selectedFinyears)
                                      ? "N/A"
                                      : parseFloat(
                                          overallMetrices.cdd
                                        ).toLocaleString()
                                  }
                                  color={"text-red-500"}
                                />
                                <Block
                                  title={"CDD days"}
                                  val={
                                    selectedFormat === "Year wise" &&
                                    !checkYearsContinuity(selectedFinyears)
                                      ? "N/A"
                                      : parseFloat(
                                          overallMetrices.cddDays
                                        ).toLocaleString()
                                  }
                                  color={"text-red-500"}
                                />
                                <Block
                                  title={"MDD Period"}
                                  val={
                                    overallMetrices.mdd === 0 ||
                                    (selectedFormat === "Year wise" &&
                                      !checkYearsContinuity(selectedFinyears))
                                      ? "N/A"
                                      : convertDate(
                                          overallMetrices.mddStartDate
                                        ) +
                                        "-" +
                                        convertDate(overallMetrices.mddEndDate)
                                  }
                                  color={"text-black-500"}
                                />
                                <Block
                                  title={"Calmar Ratio"}
                                  val={
                                    checkYearsContinuity(selectedFinyears) &&
                                    overallMetrices.mdd != 0
                                      ? parseFloat(
                                          overallMetrices.calmarRatio
                                        ).toLocaleString()
                                      : "N/A"
                                  }
                                  color={
                                    overallMetrices.calmarRatio > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                                <Block
                                  title={"Sharpe Ratio"}
                                  val={overallMetrices.sharpeRatio}
                                  color={
                                    overallMetrices.sharpeRatio > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                                <Block
                                  title={"Annualised Pnl"}
                                  val={parseFloat(
                                    overallMetrices.annualisedPnl
                                  ).toLocaleString()}
                                  color={
                                    overallMetrices.annualisedPnl > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                                <Block
                                  title={"Positve days"}
                                  val={overallMetrices.posDays}
                                  color={"text-green-500"}
                                />
                                <Block
                                  title={"Negative days"}
                                  val={overallMetrices.negDays}
                                  color={"text-red-500"}
                                />
                                <Block
                                  title={"Best Day Pnl"}
                                  val={parseFloat(
                                    overallMetrices.bestPnl
                                  ).toLocaleString()}
                                  color={"text-green-500"}
                                />
                                <Block
                                  title={"Worst Day Pnl"}
                                  val={parseFloat(
                                    overallMetrices.worstPnl
                                  ).toLocaleString()}
                                  color={"text-red-500"}
                                />

                                <Block
                                  title={"Average Pnl"}
                                  val={parseFloat(
                                    overallMetrices.meanPnl
                                  ).toLocaleString()}
                                  color={
                                    overallMetrices.meanPnl > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                                <Block
                                  title={"ROC"}
                                  val={parseFloat(overallMetrices.roc)}
                                  color={
                                    overallMetrices.roc > 0
                                      ? "text-green-500"
                                      : "text-red-500"
                                  }
                                />
                              </div>{" "}
                            </>

                            <div class="flex mx-auto mt-8">
                              {profitPieData.length > 0 ? (
                                <PieChart
                                  type={"bt"}
                                  data={profitPieData}
                                  title={
                                    "Strategy wise profit in combined years"
                                  }
                                />
                              ) : (
                                <></>
                              )}
                              {lossPieData.length > 0 ? (
                                <PieChart
                                  type={"bt"}
                                  data={lossPieData}
                                  title={"Strategy wise loss in combined years"}
                                />
                              ) : (
                                <></>
                              )}
                            </div>
                            {overallMetrices.chartData.length > 0 ? (
                              <div class="mb-9 mx-auto w-full">
                                <LineChart
                                  data={overallMetrices.chartData}
                                  yKey={"netPnl"}
                                  text={"Netpnl vs Date"}
                                />
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {flag ? (
                        <p class="font-bold text-center mt-6 text-lg">
                          No data exists!{" "}
                        </p>
                      ) : null}
                    </>
                  )}{" "}
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard_bt;