import React from "react";
import { useNavigate } from "react-router-dom";
import showErrorSnackbar from "./instance_components/Snackbar";
import { useDispatch } from "react-redux";
import { setEmail, setToken, setTokenExpiryTime} from "../redux/slices/credentials";
import { clearUserSession } from "../utils";

const Header = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  return (
    <div class="py-4 w-full h-fit bg-sky-700 flex justify-between items-center">
      <p class="text-white pl-3 text-2xl font-bold">26 Miles Dashboard</p>
      <div class="flex">
        {props.children}
        <div
          onClick={() => {
            clearUserSession(dispatch);
            navigate("/");
          }}
        >
          <p class="self-center text-white font-bold font-custom1 text-lg hover:scale-110 cursor-pointer mx-5">
            Logout
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
