import { useState } from "react";
import { LiaDownloadSolid } from "react-icons/lia";

export default function HoverDropdown(props) {
  const [open, setOpen] = useState(false);
  // console.log("icon", props.title, props.icon)
  return (
    <div className="flex justify-center">
      <div onMouseLeave={() => setOpen(false)} className="relative px-3">
        <div
          onMouseOver={() => setOpen(true)}
          className="flex items-center bg-wborder rounded-md"
        >
          <p class="self-center text-white font-bold font-custom1 text-lg hover:scale-110 cursor-pointer mx-5">
            {props.title}
          </p>
        </div>
        <ul
          className={`absolute right-0 top-0 w-full py-2 mt-7 rounded-lg shadow-xl z-50 bg-white ${
            open ? "block" : "hidden"
          }`}
        >
          {Object.keys(props.content).map((str) => {
            const func = props.content[str];
            return (
              <div
                onClick={str === "Summary" ? () => func(props.data) : func}
                class="py-2 bg-white cursor-pointer flex items-center hover:bg-gray-100 z-50"
              >
                {" "}
                <li className="flex w-full items-center px-3 py-1 text-sm z-50 font-medium">
                  {str}
                </li>
                {props.icon ? <LiaDownloadSolid /> : null}
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
