"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
  useEffect,
} from "react";
// import { createRoot } from 'react-dom/client';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axios from "axios";
import { convertDate } from "../utils";
import showErrorSnackbar from "./instance_components/Snackbar";
import LoaderComp from "./LoaderComp";
// Sample data

// Define the React component
const GroupedTable_bt = (props) => {
  const [tableData, setTableData] = useState(props.tableData);
  const [backupTableData, setBackupTableData] = useState(props.tableData);
  const [originalData, setOriginalData] = useState(props.tableData);
  // const [editedValue, setEditedValue] = useState("")
  const [originalMetrices, setOriginalMetrices] = useState(
    props.overallMetrices
  );
  const [originalPieProfitData, setOriginalPieProfitData] = useState(
    props.profitPieData
  );
  const [originalPieLossData, setOriginalPieLossData] = useState(
    props.lossPieData
  );
  // console.log('props', props.tableData);
  useEffect(() => {
    setTableData(props.tableData);
    setOriginalData(props.tableData);
    setOriginalMetrices(props.overallMetrices);
    setOriginalPieLossData(props.lossPieData);
    setOriginalPieProfitData(props.profitPieData);
  }, [props.tableData]);
  //  console.log('comparing', tableData, backupTableData);

  const allocationChange = (event) => {
    const newVal = event.newValue;
    const strategy = event.data.strategy;

    let changedData = tableData.map((item) => ({
      ...item,
      allocation: item.strategy === strategy ? newVal : item.allocation,
    }));
    // setEditedValue(newVal.toString())
    setTableData(changedData);

    // console.log('fil', filterData)
  };

  // console.log('original', originalMetrices)
  const resetData = () => {
    let tempData = originalData.map((item) => ({
      ...item,
      allocation: 1,
    }));
    setTableData(tempData);
    setBackupTableData(tempData);
    props.setOverallMetrices(originalMetrices);
    props.setProfitPieData(originalPieProfitData);
    props.setLossPieData(originalPieLossData);
  };

  const updateMdd = async () => {
    // console.log("updated", tableData);
    props.setUpdateLoader(true);
    let yearStrategyAllocationMap = {};
    let years = [];
    let strategies = [];
    let pieProfitData = [];
    let pieLossData = [];
    let overallProfit = 0;
    let overallLoss = 0;
    // console.log("data", tableData)
    tableData.forEach((obj) => {
      if (!years.includes(obj.year)) years.push(obj.year);
      strategies.push(obj.strategy);
      const key = `${obj.year}_${obj.strategy}`;
      yearStrategyAllocationMap[key] = obj.allocation;
    });

    // console.log('map', yearStrategyAllocationMap)
    try {
      let body = { yearStrategyAllocationMap, years, strategies };
      let responseData = await axios.post("/api/bt/updateMdd", body, {
        headers: props.headers,
      });
      console.log("res", responseData);
      let chartFormattedData = [];

      responseData.data.overallUpdatedData.chartData.forEach((item) => {
        const chartObj = {
          date: convertDate(item.date),
          netPnl: item.netPnl,
        };
        chartFormattedData.push(chartObj);
      });

      responseData.data.strategyWiseData.forEach((item) => {
        const category = `${item.strategy}`;
        // if (item.netPnl === "N/A") item.netPnl = 0;
        // overallNetPnl += item.netPnl;
        item.netpnl = parseInt(item.netpnl);
        const obj = {
          category,
          netpnl: item.netpnl,
        };
        // pieData.push(obj);
        if (obj.netpnl !== "N/A" && obj.netpnl >= 0) {
          pieProfitData.push(obj);
          overallProfit += obj.netpnl;
        } else if (obj.netpnl !== "N/A" && obj.netpnl < 0) {
          obj.netpnl = -obj.netpnl;
          pieLossData.push(obj);
          overallLoss += obj.netpnl;
        }
        // item.netPnl = parseFloat((item.netPnl / item.uniqueDates) * 252);
      });
      pieProfitData.length > 0 &&
        pieProfitData.forEach((item) => {
          item.netpnl = (item.netpnl / overallProfit) * 100;
        });
      pieLossData.length > 0 &&
        pieLossData.forEach((item) => {
          item.netpnl = (item.netpnl / overallLoss) * 100;
        });

      props.setOverallMetrices({
        netpnl: responseData.data.overallUpdatedData.overallNetPnl,
        tv: responseData.data.overallUpdatedData.overallTv,
        calmarRatio: responseData.data.overallUpdatedData.overallCalmarRatio,
        sharpeRatio: responseData.data.overallUpdatedData.overallSharpeRatio,
        roc: responseData.data.overallUpdatedData.overallRoc,
        mdd: responseData.data.overallUpdatedData.overallMdd,
        cdd: responseData.data.overallUpdatedData.overallCdd,
        mddDays: responseData.data.overallUpdatedData.overallMddDays,
        cddDays: responseData.data.overallUpdatedData.overallCddDays,
        posDays: responseData.data.overallUpdatedData.overallPositiveDays,
        negDays: responseData.data.overallUpdatedData.overallNegativeDays,
        chartData: chartFormattedData,
        mddStartDate: responseData.data.overallUpdatedData.mddStartDate,
        mddEndDate: responseData.data.overallUpdatedData.mddEndDate,
        worstPnl: responseData.data.overallUpdatedData.overallWorstPnl,
        bestPnl: responseData.data.overallUpdatedData.overallBestPnl,
        meanPnl: responseData.data.overallUpdatedData.overallMeanPnl,
        annualisedPnl: responseData.data.overallUpdatedData.overallAnnualisedPnl
      });
      props.setProfitPieData(pieProfitData);
      props.setLossPieData(pieLossData);
    } catch (err) {
      console.log("error happened", err);
      props.setUpdateLoader(false);
      showErrorSnackbar(err.message);
      // if (err.response.status === 401 || err.response.status === 403)
      //   navigate("/");
      return;
    }
    props.setUpdateLoader(false);
    // console.log('allocated map', yearStrategyAllocationMap);
  };

  const currencyFormatter = (params) => {
    if (params.value !== 0 && !params.value) return "";
    return typeof params.value === Number
      ? params.value.toLocaleString()
      : Math.floor(params.value).toLocaleString();
  };

  // Define column definitions
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Strategy",
      width: 300,
      showRowGroup: "strategy",
      cellRenderer: "agGroupCellRenderer",
      suppressMovable: true,
      pinned: "left",
      cellStyle: () => {
        return { fontSize: 12 };
      },
    },
    {
      field: "strategy",
      rowGroup: true,
      hide: true,
      sortable: true,

      sort: "asc",
      aggFunc: (params) => {
        console.log("params", params);
      },
    },
    {
      field: "allocation",
      editable: true,
      sortable: true,
      width: 150,
      aggFunc: (event) => {
      },
    },
    {
      field: "year",
      width: 150,
      cellStyle: () => {
        return { fontSize: 12 };
      },
    },

    {
      headerName: "Net Pnl",
      field: "netpnl",

      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: currencyFormatter,
      aggFunc: "sum",
    },
    {
      field: "calmarRatio",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
    },
    {
      field: "sharpeRatio",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
    },

    {
      headerName: "Worst Day Pnl",
      field: "worstPnl",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: currencyFormatter,
    },
    {
      field: "mdd",
      cellStyle: { color: "red", fontWeight: 500 },
      valueFormatter: currencyFormatter,
    },
    {
      field: "roc",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      sortable: true,
      resizable: true,
      // sort: "desc",
    };
  }, []);
  const gridOptions = {
    columnDefs: columnDefs,
    suppressCloseOnEditor: true,
    suppressAggFuncInHeader: true,
    defaultColDef: defaultColDef,
    rememberGroupStateWhenNewData: true,
  };

  const onGridReady = (params) => {
    // You can do additional setup here if needed
    return <span style={{ marginLeft: 60 }}>{params.value}</span>;
  };
  return (
    <>
      <>
        <div
          className="ag-theme-alpine"
          style={{
            width: "100%",
            height: 500,
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <AgGridReact
            gridOptions={gridOptions}
            rowData={tableData}
            onGridReady={onGridReady}
            animateRows={true}
            groupDisplayType="custom"
            onCellValueChanged={allocationChange}
            groupHideOpenParents={true}
            immutableData={true}
            suppressRowClickSelection={true}
          />
        </div>
        <div class="flex ">
          <div
            onClick={resetData}
            class="mt-3 flex justify-center items-center bg-cyan-950 text-white text-sm w-fit py-1 px-2 rounded-md mx-auto cursor-pointer hover:scale-110 self-end"
          >
            <p>Reset</p>
          </div>

          <div
            class="mt-3 flex justify-center items-center bg-cyan-950 text-white text-sm w-fit py-1 px-2 rounded-md mx-auto cursor-pointer hover:scale-110 self-end"
            onClick={updateMdd}
          >
            <p>Update MDD</p>
          </div>
        </div>
      </>
    </>
  );
};
// Export the component
export default GroupedTable_bt;
