import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import {
  currencyMultipliers,
  daysToExpiry,
  exchangeProperties,
  exchanges,
  multipliers,
  numericRegex,
} from "../data";
import FilterDropdown from "../components/FilterDropdown";
import DropdownHelper from "../components/Helper";
import MarketTable from "../components/MarketTable";
import {
  formatDate,
  getCombinedExpData,
  getFilteredData,
  getMarketChartData,
} from "../utils";
import CalendarComp from "../components/Calendar";
import { useNavigate } from "react-router-dom";
import HoverDropdown from "../components/HoverDropdown";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import LoaderComp from "../components/LoaderComp";
import { fetchExchangeData } from "../utils/api";
import LineChart from "../components/Linechart";
import SessionExpiredDialog from "../components/SessionExpiredDialog";
import { useSelector } from "react-redux";
import MultipleLineChart from "../components/MultipleLineChart";

const Market = () => {
  const { tokenExpiryTime, token } = useSelector((state) => state.auth);
  const [exchange, setExchange] = useState("CME");
  const [symbol, setSymbol] = useState("all");
  const [exchanges, setExchanges] = useState(["CME"]);
  const [symbols, setSymbols] = useState(["all"]);
  const [allExchangeData, setAllExchangeData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rollingDays, setRollingDays] = useState("1");
  const [numberOfDays, setNumberOfDays] = useState("1");
  const [multiplier, setMultiplier] = useState("USD");
  const [currentChartData, setCurrentChartData] = useState([]);
  const [originalChartData, setOriginalChartData] = useState([]);
  const [symbolGraph, setSymbolGraph] = useState("all");
  const [startDateGraph, setStartDateGraph] = useState(new Date());
  const [currentChartData_2, setCurrentChartData_2] = useState([]);
  const [originalChartData_2, setOriginalChartData_2] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [expChartData_1, setExpChartData_1] = useState([]);
  const [expChartData_2, setExpChartData_2] = useState([]);
  const [selectedDaysToExp, setSelectedDaysToExp] = useState(daysToExpiry);
  const [daysToExp, setDaysToExp] = useState(daysToExpiry);
  const [inputHolder, setInputHolder] = useState("");
  const [selectedExpiryDaysData, setSelectedExpiryDaysData] = useState([]);

  // const []

  const headers = {
    Authorization: `Bearer ${
      token === null ? localStorage.getItem("token") : token
    }`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };

  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const handleRollingDaysChange = (event) => {
    const value = event.target.value;

    if (numericRegex.test(value) || value == "") {
      setRollingDays(value);
    }
  };

  const handlePercentileDaysChange = (event) => {
    const value = event.target.value;

    if (numericRegex.test(value) || value == "") {
      setNumberOfDays(value);
    }
  };

  const downloadDailyTvData = (symbol) => {
    const filterData = allExchangeData.filter(
      (item) => item.underlying === symbol && item.exchange === exchange
    );

    const data =
      Object.keys(filterData[0]).join(",") +
      "\n" +
      filterData.map((obj) => Object.values(obj).join(",")).join("\n");
    // console.log("summary", summaryData)

    const blob = new Blob([data], {
      type: "application/json",
    });
    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${exchange}_${symbol}_tv.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadSummary = (data) => {
    const summaryData =
      Object.keys(data[0]).join(",") +
      "\n" +
      data.map((obj) => Object.values(obj).join(",")).join("\n");
    // console.log("summary", summaryData)
    console.log("summary", summaryData);
    const blob = new Blob([summaryData], {
      type: "application/json",
    });

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `summary_${exchange}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Make a GET request to your backend API endpoint
        const data1 = fetchExchangeData("/api/liquidityData", headers);
        const data2 = fetchExchangeData("/api/liquidityData/nse1", headers);
        const data3 = fetchExchangeData("/api/liquidityData/nse2", headers);
        const data4 = fetchExchangeData("/api/liquidityData/nse3", headers);
        const data5 = fetchExchangeData("/api/liquidityData/nse4", headers);
        const data6 = fetchExchangeData("/api/liquidityData/nse5", headers);
        const data7 = fetchExchangeData("/api/liquidityData/nse6", headers);
        const optionData = fetchExchangeData(
          "/api/liquidityData/options",
          headers
        );

        const [res1, res2, res3, res4, res5, res6, res7, res8] =
          await Promise.all([
            data1,
            data2,
            data3,
            data4,
            data5,
            data6,
            data7,
            optionData,
          ]);
        const allData = res1.concat(res2, res3, res4, res5, res6, res7, res8);

        // const allData = response.data.liquidityData;
        // console.log("market response", response)
        allData.sort((a, b) => a.date - b.date);
        let lastDate = "";
        let date = new Date();
        if (allData.length > 0) {
          lastDate = allData[allData.length - 1].date;
          let year = lastDate.substring(0, 4);
          let month = lastDate.substring(4, 6) - 1;
          let day = lastDate.substring(6, 8);

          date = new Date(year, month, day);
        }

        let initialExchange = "CME";

        let exchangeData = allData.filter(
          (item) => item.exchange === initialExchange
        );

        const uniqueSymbols = [
          ...new Set(exchangeData.map((item) => item.underlying)),
        ];
        const allExchanges = [...new Set(allData.map((item) => item.exchange))];

        let data = getFilteredData(
          exchangeData,
          uniqueSymbols,
          "20",
          "500",
          1,
          exchangeProperties["CME"]
        );
        let tableData = data.filter((item) => item.date === lastDate);
        setAllExchangeData(allData);
        setDate(date);
        setExchanges(allExchanges);
        setData(data);
        setTableData(tableData);
        setOriginalData(exchangeData);
        setRollingDays("20");
        setNumberOfDays("500");
        setSymbol("all");
        uniqueSymbols.unshift("all");
        setSymbols(uniqueSymbols);
        setLoading(false);
      } catch (error) {
        showErrorSnackbar(error.message);
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);

  useEffect(() => {
    // setLoading(true)
    let exchangeData = allExchangeData.filter(
      (item) => item.exchange === exchange
    );
    exchangeData.sort((a, b) => a.date - b.date);

    if (exchange === "NSE_OPTIONS") {
      const expiryDates = Array.from(
        new Set(exchangeData.map((item) => item.expiry))
      );
      setAnnotations(expiryDates);
    }

    let date = new Date();
    let lastDate = "";
    const uniqueSymbols = [
      ...new Set(exchangeData.map((item) => item.underlying)),
    ];

    if (exchangeData.length > 0) {
      lastDate = exchangeData[exchangeData.length - 1].date;
      let year = lastDate.substring(0, 4);
      let month = lastDate.substring(4, 6) - 1;
      let day = lastDate.substring(6, 8);

      date = new Date(year, month, day);
    }

    let obj = currencyMultipliers[exchange];
    let temporaryMultiplier;
    for (let key in obj) {
      if (obj[key] == 1) {
        // console.log("key", key);
        temporaryMultiplier = key;
        setMultiplier(key);
      }
    }
    let multiplyingFactor = currencyMultipliers[exchange][temporaryMultiplier];

    let data = getFilteredData(
      exchangeData,
      uniqueSymbols,
      "20",
      "500",
      multiplyingFactor,
      exchangeProperties[exchange]
    );

    let tableData = data.filter((item) => item.date === lastDate);
    setOriginalData(exchangeData);
    setData(data);
    setTableData(tableData);
    setRollingDays("20");
    setNumberOfDays("500");
    setDate(date);
    setSymbolGraph("all");
    setSymbol("all");
    uniqueSymbols.unshift("all");
    setSymbols(uniqueSymbols);
    // setLoading(false)
  }, [exchange]);

  useEffect(() => {
    let formattedDate = formatDate(date);

    let filteredData = data.filter((item) => item.date === formattedDate);

    if (symbol !== "all") {
      filteredData = filteredData.filter((item) => item.underlying === symbol);
    }
    // console.log("filter", filteredData);
    setTableData(filteredData);
    setSymbolGraph("all");
  }, [date, symbol]);

  useEffect(() => {
    const { chartTempData, firstDate } = getMarketChartData(
      data,
      symbolGraph,
      "rollingMean_1"
    );

    if (exchange === "NSE_OPTIONS") {
      let overallExpData1 = [];
      let overallExpData2 = [];
      const { chartTempData } = getMarketChartData(
        data,
        symbolGraph,
        "rollingMean_2"
      );

      let nonNullData = data.filter((item) => item.days_to_expiry !== null);

      let filteredData = nonNullData.filter((item) =>
        selectedDaysToExp.includes(item["days_to_expiry"].toString())
      );

      // console.log("filter", filteredData);

      selectedDaysToExp.map((exp) => {
        let finalData1 = getCombinedExpData(
          "rollingMean_1",
          exp,
          filteredData,
          symbolGraph,
          nonNullData
        );
        let finalData2 = getCombinedExpData(
          "rollingMean_2",
          exp,
          filteredData,
          symbolGraph,
          nonNullData
        );

        const expObj1 = {
          name: exp,
          data: finalData1,
        };

        const expObj2 = {
          name: exp,
          data: finalData2,
        };

        if (finalData1 && finalData1.length > 0) overallExpData1.push(expObj1);
        if (finalData2 && finalData2.length > 0) overallExpData2.push(expObj2);
      });
      if (overallExpData1 && overallExpData1.length > 0)
        overallExpData1.sort((a, b) => a.name - b.name);
      if (overallExpData2 && overallExpData2.length > 0)
        overallExpData2.sort((a, b) => a.name - b.name);
      // console.log("Overall exp", overallExpData1, overallExpData2);
      setExpChartData_1(overallExpData1);
      setExpChartData_2(overallExpData2);
      setOriginalChartData_2(chartTempData);
    }

    setStartDateGraph(firstDate);
    setOriginalChartData(chartTempData);
  }, [symbolGraph]);

  // console.log("originalChartData2", originalChartData_2)

  useEffect(() => {
    const filteredChartData = originalChartData.filter(
      (item) => item.date >= formatDate(startDateGraph)
    );
    if (exchange === "NSE_OPTIONS") {
      const filteredChartData_2 = originalChartData_2.filter(
        (item) => item.date >= formatDate(startDateGraph)
      );
      setCurrentChartData_2(filteredChartData_2);
    }
    setCurrentChartData(filteredChartData);
  }, [startDateGraph]);

  useEffect(() => {
    if (rollingDays.length > 0 && numberOfDays.length > 0) {
      let multiplyingFactor = currencyMultipliers[exchange][multiplier];
      let allData = getFilteredData(
        originalData,
        symbols,
        rollingDays,
        numberOfDays,
        multiplyingFactor,
        exchangeProperties[exchange]
      );
      // console.log("data", allData);
      let data = allData.filter(
        (item) =>
          (symbol === "all" || item.underlying === symbol) &&
          item.date === formatDate(date)
      );

      setData(allData);
      setTableData(data);
    } else {
      setTableData([]);
      setData([]);
    }
    setSymbolGraph("all");
  }, [rollingDays, numberOfDays, multiplier]);

  useEffect(() => {
    if (exchange === "NSE_OPTIONS") {
      let overallExpData1 = [];
      let overallExpData2 = [];

      let nonNullData =
        data &&
        data.length > 0 &&
        data.filter((item) => item.days_to_expiry !== null);

      let filteredData =
        nonNullData &&
        nonNullData.length > 0 &&
        nonNullData.filter((item) =>
          selectedDaysToExp.includes(item["days_to_expiry"].toString())
        );
      selectedDaysToExp.map((exp) => {
        let finalData1 = getCombinedExpData(
          "rollingMean_1",
          exp,
          filteredData,
          symbolGraph,
          nonNullData
        );
        let finalData2 = getCombinedExpData(
          "rollingMean_2",
          exp,
          filteredData,
          symbolGraph,
          nonNullData
        );

        const expObj1 = {
          name: exp,
          data: finalData1,
        };

        const expObj2 = {
          name: exp,
          data: finalData2,
        };

        if (finalData1 && finalData1.length > 0) overallExpData1.push(expObj1);
        if (finalData2 && finalData2.length > 0) overallExpData2.push(expObj2);
      });

      if (overallExpData1 && overallExpData1.length > 0)
        overallExpData1.sort((a, b) => a.name - b.name);
      if (overallExpData2 && overallExpData2.length > 0)
        overallExpData2.sort((a, b) => a.name - b.name);
      setExpChartData_1(overallExpData1);
      setExpChartData_2(overallExpData2);
    }
  }, [selectedDaysToExp]);

  return (
    <div class="w-full">
      <div>
        <Header>
          <div>
            <HoverDropdown
              title={"Market"}
              content={{
                Home: () => {
                  navigate("/live_data");
                },
                Market: () => {
                  navigate("/market");
                },
                Instances: () => {
                  navigate("/instances");
                },
                Allocation: () => {
                  navigate("/allocation");
                },
              }}
              icon={false}
            />
          </div>
          <HoverDropdown
            title={"Downloads"}
            content={{
              Summary: downloadSummary,
            }}
            data={tableData}
            icon={true}
          />
        </Header>

        <div class="flex justify-center mt-3">
          <p class="font-bold text-lg ">Market Analysis</p>
        </div>
        {new Date().getTime() > tokenExpiryTime ? (
          <SessionExpiredDialog
            expired={new Date().getTime() > tokenExpiryTime}
          />
        ) : (
          <div>
            {loading ? (
              <div class="flex justify-center items-center mt-3">
                <LoaderComp />
              </div>
            ) : (
              <>
                <div class="flex w-full ">
                  <div class="w-1/6 my-3 ml-3">
                    <DropdownHelper
                      selectedVal={exchange}
                      setSelectedVal={setExchange}
                      content={exchanges}
                      title={"Exchange"}
                    />
                  </div>
                  <div class="w-1/6 my-3 ml-3">
                    <DropdownHelper
                      selectedVal={symbol}
                      setSelectedVal={setSymbol}
                      content={symbols}
                      title={"Symbol"}
                    />
                  </div>
                  <div class="mx-3 my-3 w-fit">
                    <CalendarComp date={date} setDate={setDate} />
                  </div>

                  <div class="my-4 ml-3">
                    <input
                      type="text"
                      aria-describedby="helper-text-explanation"
                      value={rollingDays}
                      onChange={handleRollingDaysChange}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Type rolling days"
                      required
                    />
                  </div>
                  <div class="my-4 ml-3">
                    <input
                      type="text"
                      aria-describedby="helper-text-explanation"
                      value={numberOfDays}
                      onChange={handlePercentileDaysChange}
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Type Days for percentile"
                      required
                    />
                  </div>
                  <div class="w-1/6 my-3 ml-3">
                    <DropdownHelper
                      selectedVal={multiplier}
                      setSelectedVal={setMultiplier}
                      content={Object.keys(multipliers)}
                      title={"Multiplier"}
                    />
                  </div>
                  {exchange === "NSE_OPTIONS" ? (
                    <div class="w-1/8 my-3">
                      <FilterDropdown
                        selectedVals={selectedDaysToExp}
                        setSelectedVals={setSelectedDaysToExp}
                        content={daysToExp}
                        title={"Days to expiry"}
                      />{" "}
                    </div>
                  ) : null}
                </div>
              </>
            )}
            <div>
              {loading ? (
                <div> </div>
              ) : (
                <div>
                  {tableData && tableData.length > 0 ? (
                    <MarketTable
                      data={tableData}
                      setSymbolGraph={setSymbolGraph}
                      exchange={exchange}
                      downloadDailyTvData={downloadDailyTvData}
                    />
                  ) : (
                    <p class="text-center font-bold my-3">No data!</p>
                  )}
                </div>
              )}
            </div>

            {symbolGraph != "all" ? (
              <div class="w-5/6 mx-auto">
                <div class="w-fit">
                  <p class="font-semibold mt-8 mb-3">Change start date</p>
                  <CalendarComp
                    date={startDateGraph}
                    setDate={setStartDateGraph}
                  />
                </div>
                <LineChart
                  data={
                    currentChartData &&
                    currentChartData.length > 0 &&
                    currentChartData.filter(
                      (item) => item.rollingMean_1 !== null
                    )
                  }
                  yKey={"rollingMean_1"}
                  text={`Rolling mean ${
                    exchange === "NSE_OPTIONS" ? "notional" : ""
                  } turnover vs Date (${symbolGraph})`}
                  annotations={annotations}
                />
                {exchange === "NSE_OPTIONS" ? (
                  <LineChart
                    data={
                      currentChartData_2 &&
                      currentChartData_2.length > 0 &&
                      currentChartData_2.filter(
                        (item) => item.rollingMean_2 !== null
                      )
                    }
                    yKey={"rollingMean_2"}
                    text={`Rolling mean premium turnover vs Date (${symbolGraph})`}
                    annotations={annotations}
                  />
                ) : null}
                {exchange === "NSE_OPTIONS" ? (
                  <>
                    {expChartData_1 && expChartData_1.length > 0 ? (
                      <MultipleLineChart
                        data={expChartData_1.filter(
                          (item) => item.rollingMean_1 !== null
                        )}
                        yKey={"rollingMean_1"}
                        text={`Rolling mean notional turnover vs Date (${symbolGraph}), Days to expiry ${selectedDaysToExp
                          .sort()
                          .join(",")}`}
                        annotations={annotations}
                      />
                    ) : null}
                    {expChartData_2 && expChartData_2.length > 0 ? (
                      <MultipleLineChart
                        data={expChartData_2.filter(
                          (item) => item.rollingMean_2 !== null
                        )}
                        yKey={"rollingMean_2"}
                        text={`Rolling mean premium turnover vs Date (${symbolGraph}), Days to expiry ${selectedDaysToExp
                          .sort()
                          .join(",")}`}
                        annotations={annotations}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default Market;
