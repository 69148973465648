import React, { useEffect, useRef, useState } from "react";
import InputCheckbox from "./InputCheckbox";
import "./instance_components/DropdownMenu.css";

const ColumnsDropdown = ({
  content,
  setContent,
  visibleCols,
  setVisibleCols,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleCheckboxChange = (e) => {
    const item = e.target.value;
    if (e.target.checked) {
      setVisibleCols([...visibleCols, item]);
    } else {
      setVisibleCols(visibleCols.filter((vis) => vis !== item));
    }
  };

  //   useEffect(() => {
  //     onChange(selectedItems);
  //   }, [selectedItems, onChange]);

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <div className="dropdown" ref={dropdownRef}>
      <button
        className="dropdown-button"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        {"Update Columns"}
      </button>
      <div className={`dropdown-content ${isDropdownOpen ? "open" : ""}`}>
        {content.map((val) => (
          <InputCheckbox
            refer={inputRef}
            val={val}
            handleChange={handleCheckboxChange}
            content={visibleCols}
          />
        ))}
      </div>
    </div>
  );
};

export default ColumnsDropdown;
