import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useMemo, useState } from "react";
import { weightageMap } from "../data";

const WeightageTable = ({ data, tableData, setTableData }) => {
  const [rowData, setRowData] = useState(data);
  const [originalData, setOriginalData] = useState(data);
  const [originalTableData, setOriginalTableData] = useState([]);

  useEffect(() => {
    setRowData(data);
    setOriginalData(data);
    setOriginalTableData(tableData);
  }, [data]);

  const cellEditChange = (event) => {
    const feature = event.data.feature;
    const newVal = event.newValue;
    const oldVal = event.oldValue;

    const updatedData = rowData.map((item) => {
      return {
        ...item,
        weightage: item.feature === feature ? newVal : item.weightage,
      };
    });

    // console.log("original", originalTableData);

    const updatedTableData = originalTableData.map((item) => {
      let final = 0;
      updatedData.forEach((wData) => {
        let field = wData.feature;
        let weightage = wData.weightage;
        final += item[field] * weightage;
        item = {
          ...item,
          [field]: item[field] * weightage,
        };
      });
     
      item = { ...item, final: final };
      return item;

    });

    // console.log("updated", updatedTableData);
    setTableData(updatedTableData);
    setRowData(updatedData);
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
    }),
  }));

  const columnDefs = useMemo(() => [
    {
      field: "feature",
      headerName: "Feature",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "weightage",
      headerName: "Weightage",
      flex: 1,
      minWidth: 100,
      editable: true,
    },
  ]);

  return (
    <div class="mt-10">
      <div
        className="ag-theme-alpine"
        style={{
          width: "30%",
          height: 261,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {" "}
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          onCellEditingStopped={cellEditChange}
          // onCellValueChanged={filterChange_live}
        />
      </div>
    </div>
  );
};

export default WeightageTable;
