import InstanceButton from "../InstanceButton";
import InputContainer from "./InputContainer";
import RegionDropdown from "./RegionDropDown";
import { ShimmerButton, ShimmerTitle } from "react-shimmer-effects";
import { useEffect, useState } from "react";
import axios from "axios";
import showErrorSnackbar from "./Snackbar";
import { useSelector } from "react-redux";

const InstanceRow = (props) => {
	const instanceTypeHashMap = {
		nano: 0,
		micro: 1,
		small: 2,
		medium: 3,
		large: 4,
		xlarge: 5,
		"2xlarge": 6,
		"4xlarge": 7,
		"8xlarge": 8,
		"16xlarge": 9,
	};

	const { email, token } = useSelector(state => state.auth)
	// const instance = props.instance;
	const removeIgnoreInstance = props.removeIgnoreInstance;
	const addIgnoreInstance = props.addIgnoreInstance;
	const enableTimeTriggerSpecific = props.enableTimeTriggerSpecific;
	const disableTimeTriggerSpecific = props.disableTimeTriggerSpecific;
	const instanceTypes = props.instanceTypes;
	const cellStyle = {
		border: "1px solid #000", // Add borders to table cells
		padding: "4px", // Add some padding for better spacing
		textAlign: "center", // Center-align the cell content
		align: "center",
	};
	const buttonStyle = {
		// backgroundColor: "#007bff", // Set the background color to blue
		color: "white", // Set the text color to white
		width: "25px", // Set the width to 5 pixels
		height: "25px", // Set the height to 5 pixels to make it a square
		padding: "1px", // Remove padding
		border: "none", // Remove the border
		cursor: "pointer", // Add a pointer cursor on hover
		borderRadius: "2px",
		fontSize: "18px",
		margin: "2px",
	};
	const [startScheduleLoading, setStartScheduleLoading] = useState(true);
	const [stopScheduleLoading, setStopScheduleLoading] = useState(true);
	const [isLoading, setLoading] = useState(false);
	const [instance, setInstance] = useState(props.instance);
	const [startScheduleStatusList, setStartScheduleStatusList] = useState([]);
	const [stopScheduleStatusList, setStopScheduleStatusList] = useState([]);
	const [startScheduleExpressionList, setStartScheduleExpressionList] =
		useState([]);
	const [stopScheduleExpressionList, setStopScheduleExpressionList] =
		useState([]);
	const [instanceId, setInstanceId] = useState(props.instance.InstanceId);
	const numberOfComponents = 9;
	const loadingComponents = Array.from(
		{ length: numberOfComponents },
		(_, index) => (
			<td style={cellStyle}>
				<ShimmerTitle />
			</td>
		)
	);

	const headers = {
		Authorization: `Bearer ${token}`, // Use 'Bearer' followed by a space before the token
		"Content-Type": "application/json", // Set the content type if needed
	};

	const fetchInstanceData = async (
		instanceId,
		regionName,
		isAdmin,
		setEnabled
	) => {
		setLoading(true);
		let fetchedInstanceData = [];
		let instanceData = {};
		try {
			const response = await axios.post(
				"api/instances/statusSpecific",
				{
					instanceId: instanceId,
					region: regionName,
					isAdmin: isAdmin,
				},
				{
					headers,
				}
			);
			if (response.data.message !== "Successful") {
				throw new Error(
					"Some error occurred while fetching status information"
				);
			}
			fetchedInstanceData = response.data.data;
			console.log(fetchedInstanceData);
			let i = 0;
			for (i = 0; i < fetchedInstanceData.length; i++) {
				instanceData = {
					InstanceName: fetchedInstanceData[i].InstanceName,
					PrivateIpAddress: fetchedInstanceData[i].PrivateIpAddress,
					InstanceStatus: fetchedInstanceData[i].InstanceStatus,
					InstanceId: fetchedInstanceData[i].InstanceId,
					InstanceType: fetchedInstanceData[i].InstanceType,
					InstanceIgnoreStatus:
						fetchedInstanceData[i].InstanceIgnoreStatus,
					ScheduleOperationStatus:
						fetchedInstanceData[i].ScheduleOperationStatus,
					OriginalInstanceType:
						fetchedInstanceData[i].OriginalInstanceType,
					ChangeTypeOperationDowngrade:
						fetchedInstanceData[i].ChangeTypeOperationDowngrade,
					ChangeTypeOperationEnabled:
						fetchedInstanceData[i].ChangeTypeOperationEnabled,
					ChangeTypeOperationStatus:
						fetchedInstanceData[i].ChangeTypeOperationStatus,
					// InstanceStartScheduleStatusList: startScheduleStatusList,
					// InstanceStopScheduleStatusList: stopScheduleStatusList,
					// InstanceStartScheduleExpressionList:
					// 	startScheduleExpressionList,
					// InstanceStopScheduleExpressionList:
					// 	stopScheduleExpressionList,
				};
				if (
					fetchedInstanceData[i].InstanceStatus !== "running" &&
					fetchedInstanceData[i].InstanceStatus !== "stopped"
				) {
					setEnabled(false);
					break;
				}
			}
			if (i === fetchedInstanceData.length) setEnabled(true);
		} catch (err) {
			// console.error("Error fetching data:", err);
			showErrorSnackbar(err.message);
			return;
		}
		console.log(instanceData);
		setInstance(instanceData);
		setLoading(false);
	};

	const fetchStartScheduleList = async (instanceId) => {
		if (!instance.ScheduleOperationStatus) {
			setStartScheduleLoading(false);
			return;
		}
		setStartScheduleLoading(true);
		let startScheduleStatusListData = [];
		let startScheduleExpressionListData = [];
		try {
			const response = await axios.post(
				"api/timetrigger/statusSpecific",
				{
					instanceId: instance.InstanceId,
					setStart: true,
				},
				{
					headers,
				}
			);
			if (response.data.message !== "Successful") {
				throw new Error(
					"Some error occurred while fetching status information"
				);
			}

			startScheduleStatusListData = response.data.enabledList;
			startScheduleExpressionListData =
				response.data.scheduleExpressionList;
		} catch (err) {
			// console.error("Error fetching data:", err);
			showErrorSnackbar(err.message);
			return;
		}
		setStartScheduleStatusList(startScheduleStatusListData);
		setStartScheduleExpressionList(startScheduleExpressionListData);
		setStartScheduleLoading(false);
	};

	const fetchStopScheduleList = async (instanceId) => {
		if (!instance.ScheduleOperationStatus) {
			setStopScheduleLoading(false);
			return;
		}
		setStopScheduleLoading(true);
		let stopScheduleStatusListData = [];
		let stopScheduleExpressionListData = [];
		try {
			const response = await axios.post(
				"api/timetrigger/statusSpecific",
				{
					instanceId: instance.InstanceId,
					setStart: false,
				},
				{
					headers,
				}
			);
			if (response.data.message !== "Successful") {
				throw new Error(
					"Some error occurred while fetching status information"
				);
			}

			stopScheduleStatusListData = response.data.enabledList;
			stopScheduleExpressionListData =
				response.data.scheduleExpressionList;
		} catch (err) {
			// console.error("Error fetching data:", err);
			showErrorSnackbar(err.message);
			return;
		}
		setStopScheduleStatusList(stopScheduleStatusListData);
		setStopScheduleExpressionList(stopScheduleExpressionListData);
		setStopScheduleLoading(false);
	};

	useEffect(() => {
		fetchStartScheduleList(instance.instanceId);
		fetchStopScheduleList(instance.instanceId);
	}, []);
	const max_num_schedulers = 5;
	const min_num_schedulers = 1;
	const increaseStartSchedulers = () => {
		if (startScheduleStatusList.length === max_num_schedulers) {
			return showErrorSnackbar(
				`Maximum allowed schedulers are ${max_num_schedulers}`
			);
		}
		setStartScheduleLoading(true);
		let startScheduleStatusListData = [...startScheduleStatusList];
		startScheduleStatusListData.push(false);
		let startScheduleExpressionListData = [...startScheduleExpressionList];
		startScheduleExpressionListData.push("_");
		setStartScheduleStatusList(startScheduleStatusListData);
		setStartScheduleExpressionList(startScheduleExpressionListData);
		setStartScheduleLoading(false);
	};

	const decreaseStartSchedulers = () => {
		if (startScheduleStatusList.length === min_num_schedulers) {
			return showErrorSnackbar(
				`Minimum possible schedulers are ${min_num_schedulers}`
			);
		}
		let startScheduleStatusListData = [...startScheduleStatusList];
		if (
			startScheduleStatusListData[startScheduleStatusListData.length - 1]
		) {
			return showErrorSnackbar(`Cannot remove a set scheduler!`);
		}
		startScheduleStatusListData.pop();
		let startScheduleExpressionListData = [...startScheduleExpressionList];
		startScheduleExpressionListData.pop();
		setStartScheduleStatusList(startScheduleStatusListData);
		setStartScheduleExpressionList(startScheduleExpressionListData);
	};

	const increaseStopSchedulers = () => {
		if (stopScheduleStatusList.length === max_num_schedulers) {
			return showErrorSnackbar(
				`Maximum allowed schedulers are ${max_num_schedulers}`
			);
		}
		let stopScheduleStatusListData = [...stopScheduleStatusList];
		stopScheduleStatusListData.push(false);
		let stopScheduleExpressionListData = [...stopScheduleExpressionList];
		stopScheduleExpressionListData.push("_");
		setStopScheduleStatusList(stopScheduleStatusListData);
		setStopScheduleExpressionList(stopScheduleExpressionListData);
	};

	const decreaseStopSchedulers = () => {
		if (stopScheduleStatusList.length === min_num_schedulers) {
			return showErrorSnackbar(
				`Minimum allowed schedulers are ${min_num_schedulers}`
			);
		}
		let stopScheduleStatusListData = [...stopScheduleStatusList];
		if (stopScheduleStatusListData[stopScheduleStatusListData.length - 1]) {
			return showErrorSnackbar(`Cannot remove a set scheduler!`);
		}
		stopScheduleStatusListData.pop();
		let stopScheduleExpressionListData = [...stopScheduleExpressionList];
		stopScheduleExpressionListData.pop();
		setStopScheduleStatusList(stopScheduleStatusListData);
		setStopScheduleExpressionList(stopScheduleExpressionListData);
	};

	return isLoading ? (
		<tr key={instance.InstanceId}>{loadingComponents}</tr>
	) : (
		<tr key={instance.InstanceId}>
			<td style={cellStyle}>{instance.InstanceName}</td>
			<td style={cellStyle}>{instance.PrivateIpAddress}</td>
			<td style={cellStyle}>{instance.InstanceId}</td>
			<td style={cellStyle}>{instance.InstanceType}</td>
			<td style={cellStyle}>{instance.InstanceStatus}</td>

			<td style={cellStyle}>
				{instance.ScheduleOperationStatus ? (
					<InstanceButton
						text={
							instance.InstanceStatus === "running"
								? "Stop"
								: "Start"
						}
						color={
							instance.InstanceStatus === "running"
								? "red-500"
								: "green-500"
						}
						textCol={"white"}
						onClick={() => {
							const value =
								instance.InstanceStatus === "running"
									? "stop"
									: "start";
							props.showAlert(
								"Alert!",
								`Are you sure you want to ${value} "${instance.InstanceName}" ?`,
								instance.InstanceStatus === "running"
									? "stopSpecific"
									: "startSpecific",
								{
									instanceId: instance.InstanceId,
									setLoading: setLoading,
									fetchInstanceData: fetchInstanceData,
								}
							);
						}}
						isEnabled={
							instance.InstanceStatus === "running" ||
							instance.InstanceStatus === "stopped"
								? true
								: false
						}
					/>
				) : (
					<>Live Process Server</>
				)}
			</td>
			<td style={cellStyle}>
				{instance.ScheduleOperationStatus ? (
					<InstanceButton
						text={
							instance.InstanceIgnoreStatus ? "Enable" : "Disable"
						}
						// Disable -> Add to Ignore
						// Enable -> Remove from Ignore
						color={
							instance.InstanceIgnoreStatus
								? "green-500"
								: "red-500"
						}
						textCol={"white"}
						onClick={() => {
							if (instance.InstanceIgnoreStatus) {
								removeIgnoreInstance(
									instance.InstanceId,
									setLoading,
									fetchInstanceData
								);
							} else {
								addIgnoreInstance(
									instance.InstanceId,
									setLoading,
									fetchInstanceData
								);
							}
						}}
						isEnabled={
							instance.InstanceStatus === "running" ||
							instance.InstanceStatus === "stopped"
								? true
								: false
						}
					/>
				) : (
					<>Live Process Server</>
				)}
			</td>
			<td style={cellStyle}>
				{stopScheduleLoading || isLoading ? (
					<div
						style={{
							justifyContent: "center" /* Center horizontally */,
							alignItems: "center",
							margin: "2px",
							textAlign: "center",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent:
									"center" /* Center horizontally */,
								alignItems: "center",
								margin: "2px",
								textAlign: "center",
							}}
						>
							<div style={{ margin: "2px" }}>
								<ShimmerButton />
							</div>

							<div style={{ margin: "2px" }}>
								<ShimmerButton size="sm" />
							</div>
							<div style={{ margin: "2px" }}>
								<ShimmerButton size="sm" />
							</div>
						</div>
					</div>
				) : instance.ScheduleOperationStatus ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center" /* Center horizontally */,
							alignItems: "center",
						}}
					>
						<div>
							{stopScheduleStatusList.map((val, idx) => (
								<InputContainer
									placeholder={
										stopScheduleStatusList[idx]
											? stopScheduleExpressionList[idx]
											: "_"
									}
									onClick={
										stopScheduleStatusList[idx]
											? disableTimeTriggerSpecific
											: enableTimeTriggerSpecific
									}
									type={
										stopScheduleStatusList[idx]
											? "Disable"
											: "Enable"
									}
									enabled={stopScheduleStatusList[idx]}
									setStart={false}
									instanceId={instance.InstanceId}
									isMachineSpecific={true}
									id={idx}
									setLoading={setStopScheduleLoading}
									fetchScheduleList={fetchStopScheduleList}
								/>
							))}
						</div>
						<div
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<button
								style={{
									...buttonStyle,
									backgroundColor: "#09d670",
								}}
								onClick={increaseStopSchedulers}
							>
								+
							</button>
							<button
								style={{
									...buttonStyle,
									backgroundColor: "#e92222",
								}}
								onClick={decreaseStopSchedulers}
							>
								-
							</button>
						</div>
					</div>
				) : (
					<>Live Process Server</>
				)}
			</td>
			<td style={cellStyle}>
				{instance.ScheduleOperationStatus ? (
					<>
						{startScheduleLoading || isLoading ? (
							<div
								style={{
									justifyContent:
										"center" /* Center horizontally */,
									alignItems: "center",
									margin: "2px",
									textAlign: "center",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent:
											"center" /* Center horizontally */,
										alignItems: "center",
										margin: "2px",
										textAlign: "center",
									}}
								>
									<div style={{ margin: "2px" }}>
										<ShimmerButton />
									</div>

									<div style={{ margin: "2px" }}>
										<ShimmerButton size="sm" />
									</div>
									<div style={{ margin: "2px" }}>
										<ShimmerButton size="sm" />
									</div>
								</div>
							</div>
						) : (
							<div
								style={{
									display: "flex",
									justifyContent:
										"center" /* Center horizontally */,
									alignItems: "center",
								}}
							>
								<div>
									{startScheduleStatusList.map((val, idx) => (
										// 		<div style = {{
										// 			display: flex;
										// 			justify-content: center; /* Center horizontally */
										// 			align-items: center; /* Center vertically */
										// }}>

										<InputContainer
											placeholder={
												startScheduleStatusList[idx]
													? startScheduleExpressionList[
															idx
													  ]
													: "_"
											}
											onClick={
												startScheduleStatusList[idx]
													? disableTimeTriggerSpecific
													: enableTimeTriggerSpecific
											}
											type={
												startScheduleStatusList[idx]
													? "Disable"
													: "Enable"
											}
											enabled={
												startScheduleStatusList[idx]
											}
											setStart={true}
											instanceId={instance.InstanceId}
											isMachineSpecific={true}
											id={idx}
											setLoading={setStartScheduleLoading}
											fetchScheduleList={
												fetchStartScheduleList
											}
											instanceTypes={instanceTypes.filter(
												(type) =>
													instanceTypeHashMap[
														type.split(".")[1]
													] <=
													instanceTypeHashMap[
														instance.OriginalInstanceType.split(
															"."
														)[1]
													]
											)}
											instanceType={
												instance.OriginalInstanceType
											}
											changeTypeOperationStatus={
												instance.ChangeTypeOperationStatus
											}
										/>
									))}
								</div>
								<div
									style={{
										display: "flex",
										flexDirection: "column",
									}}
								>
									<button
										style={{
											...buttonStyle,
											backgroundColor: "#09d670",
										}}
										onClick={increaseStartSchedulers}
									>
										+
									</button>
									<button
										style={{
											...buttonStyle,
											backgroundColor: "#e92222",
										}}
										onClick={decreaseStartSchedulers}
									>
										-
									</button>
								</div>
							</div>
						)}
					</>
				) : (
					<>Live Process Server</>
				)}
			</td>

			<td style={cellStyle}>
				{instance.ScheduleOperationStatus ? (
					instance.ChangeTypeOperationStatus ? (
						instance.ChangeTypeOperationEnabled ? (
							instance.ChangeTypeOperationDowngrade ? (
								<RegionDropdown
									selectedVal={"Downgrade"}
									content={instanceTypes.filter(
										(type) =>
											instanceTypeHashMap[
												type.split(".")[1]
											] <
											instanceTypeHashMap[
												instance.OriginalInstanceType.split(
													"."
												)[1]
											]
									)}
									setSelectedVal={() => {}}
									onSelect={(instanceType) => {
										props.showAlert(
											"Alert!",
											`Are you sure you want to downgrade "${instance.InstanceName}" to "${instanceType}"?`,
											"changeInstanceType",
											{
												instanceId: instance.InstanceId,
												instanceType: instanceType,
												setLoading: setLoading,
												fetchInstanceData:
													fetchInstanceData,
											}
										);
									}}
								/>
							) : (
								<InstanceButton
									text={"Revert"}
									// Disable -> Add to Ignore
									// Enable -> Remove from Ignore
									isEnabled={true}
									color="zinc-300"
									textCol={"black"}
									onClick={() => {
										props.showAlert(
											"Alert!",
											`Are you sure you want to revert "${instance.InstanceName}" to "${instance.OriginalInstanceType}"?`,
											"changeInstanceType",
											{
												instanceId: instance.InstanceId,
												instanceType:
													instance.OriginalInstanceType,
												setLoading: setLoading,
												fetchInstanceData:
													fetchInstanceData,
											}
										);
									}}
								/>
							)
						) : (
							<>Not Stopped</>
						)
					) : (
						<>Not Tagged</>
					)
				) : (
					<>Live Process Server</>
				)}
			</td>
		</tr>
	);
};

export default InstanceRow;
