import React, { useEffect, useState } from "react";

const ToggleButton = ({ flag }) => {
  return (
    <button
      className={`relative w-12 h-6 rounded-full bg-gray-400 focus:outline-none ${
        flag ? "bg-green-500" : ""
      }`}
    //   onClick={handleToggle}
    >
      <span
        className={`absolute left-0 top-0 w-6 h-6 rounded-full bg-white transform transition-transform ${
          flag ? "translate-x-full" : ""
        }`}
      ></span>
    </button>
  );
};

export default ToggleButton;
