import React, { useEffect, useRef, useState } from "react";
import "./styling/filterDropdown.css"
import InputCheckbox from "./InputCheckbox";

const FilterDropdown = ({ selectedVals, setSelectedVals, content, title }) => {
  const [open, setOpen] = useState(false);
  const inputRef = useRef(null);
  //const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);
  const handleOpen = () => {
    setOpen((open) => !open);
  };
  // if (content.length === 0) selectedVal = "No Option Available";
 
  const handleCheckboxChange = (e) => {
    const item = e.target.value;
    if (e.target.checked) {
      if (item === "Select All") {
        setSelectedVals(content.filter((item) => item !== "Clear All" && item !== 'Select All'));
      } else if (item === "Clear All") {
        setSelectedVals([]);
      } else setSelectedVals([...selectedVals, item]);
    } else {
      
      setSelectedVals(selectedVals.filter((vis) => vis !== item));
    }
  };

  return (
    <div class="relative mt-1 ml-1 mr-1 mb-1 select-none" ref={ref}>
      <div
        class="p-2 bg-zinc-200 shadow-md font-bold flex items-center text-slate-500 justify-between cursor-pointer text-base -z-50"
        onClick={content.length ? handleOpen : () => {}}
      >
        {`Select ${title}`}
      </div>
      {open ? (
        <div class="absolute top-full left-0 py-3 bg-zinc-100 font-normal text-slate-500 shadow-md w-11/12 max-h-72 overflow-scroll z-10">
          {content &&
            content.length &&
            content.map((str) => {
              return (
                <div class="p-1 cursor-pointer hover:bg-slate-50 transition-all z-10">
                  <InputCheckbox
                    refer={inputRef}
                    val={str}
                    handleChange={handleCheckboxChange}
                    content={selectedVals}
                    title={title}
                  />
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};

export default FilterDropdown;
