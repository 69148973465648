
import React, { useEffect, useRef, useState } from "react";
import InputCheckbox from "./InputCheckbox";

const SearchableDropdown = ({
  content,
  id,
  title,
  selectedVals,
  setSelectedVals,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState(content);
  const [values, setValues] = useState(content);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef(null);
  // console.log('values', options, values)
  useEffect(() => {
    setOptions(content);
    setValues(content);
  }, [content]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    // Filter options based on the input value
    const filteredOptions = values.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    filteredOptions.push(...selectedVals);
    setOptions(filteredOptions);
    setIsDropdownOpen(true);
  };

  const handleCheckboxChange = (e) => {
    const item = e.target.value;
    if (e.target.checked) {
      if (item === "Select All") {
        setSelectedVals(
          values.filter((item) => item !== "Clear All" && item !== "Select All")
        );
      } else if (item === "Clear All") {
        setSelectedVals([]);
      } else setSelectedVals([...selectedVals, item]);
    } else {
      setSelectedVals(selectedVals.filter((vis) => vis !== item));
    }
  };

  const handleInputFocus = (e) => {
    // Add additional logic here if needed
    setIsDropdownOpen(true);
  };

  return (
    <div class="relative mt-1 ml-1 mr-1 mb-1 select-none" ref={ref}>
      <div class="bg-zinc-200 shadow-md font-bold flex items-center text-slate-500 justify-between cursor-pointer text-base border-none">
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Select Strategies"
          onFocus={handleInputFocus}
          class="outline-none focus:border-none bg-zinc-200"
        />
      </div>
      {isDropdownOpen > 0 && (
        <div class="absolute top-full left-0 py-3 bg-zinc-100 font-normal text-slate-500 shadow-md w-11/12 max-h-72 overflow-scroll z-10">
          {options.map((option, index) => (
            <div
              class={`p-1 w-full bg-zinc-100 cursor-pointer hover:bg-slate-50 transition-all z-10`}
            >
              <InputCheckbox
                refer={inputRef}
                val={option}
                handleChange={handleCheckboxChange}
                content={selectedVals}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchableDropdown;
