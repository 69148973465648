import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../components/DataTable";
import showErrorSnackbar from "../components/instance_components/Snackbar";
import HoverDropdown from "../components/HoverDropdown";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import AllocationTable from "../components/AllocationTable";
import LoaderComp from "../components/LoaderComp";
import ToggleSwitch from "../components/ToggleSwitch";
import { logics, weightageInitialData } from "../data";
import DropdownHelper from "../components/Helper";
import WeightageTable from "../components/WeightageTable";
import Card from "../components/Card";
import { useSelector } from "react-redux";
import SessionExpiredDialog from "../components/SessionExpiredDialog";

const Allocation = () => {
  const navigate = useNavigate();
  const {tokenExpiryTime} = useSelector(state => state.auth)
  const [tableData, setTableData] = useState([]);
  const [headData, setHeadData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [weightageData, setWeightageData] = useState([
    ...weightageInitialData.map((obj) => ({ ...obj })),
  ]);
  const { token } = useSelector((state) => state.auth);

  const headers = {
    Authorization: `Bearer ${
      token === null ? localStorage.getItem("token") : token
    }`, // Use 'Bearer' followed by a space before the token
    "Content-Type": "application/json", // Set the content type if needed
  };

  const resetData = () => {
    setWeightageData(weightageInitialData);
    setTableData(originalData);
  };

  useEffect(() => {
    const getAllocationData = async () => {
      try {
        const response = await axios.get("/api/allocation", { headers });
        const data = response.data.allocationData;
        console.log("response", response);
        let columns = [
          "logic",
          "asset",
          "client",
          "netpnl",
          "tv",
          "volatility",
          "cdd",
          "netpnlEnv",
          "final",
        ];
        if (data.length > 0) {
          columns = Object.keys(data[0]);
        }

        setOriginalData(data);
        setHeadData(columns);
        setTableData(data);
      } catch (error) {
        showErrorSnackbar(error.message);
        console.error("Error fetching data:", error);
      }
    };

    getAllocationData();
  }, []);

  return (
    <div class="w-full">
      <Header>
        <div>
          <HoverDropdown
            title={"Allocation"}
            content={{
              Home: () => {
                navigate("/live_data");
              },
              Market: () => {
                navigate("/market");
              },
              Instances: () => {
                navigate("/instances");
              },
              Allocation: () => {
                navigate("/allocation");
              },
            }}
            icon={false}
          />
        </div>
      </Header>

      {new Date().getTime() > tokenExpiryTime ? (
        <SessionExpiredDialog
          expired={new Date().getTime() > tokenExpiryTime}
        />
      ) : (
        <>
          {tableData.length > 0 ? (
            <div>
              <WeightageTable
                data={weightageData}
                tableData={tableData}
                setTableData={setTableData}
              />
              <div
                onClick={resetData}
                class="mt-3 flex justify-center items-center bg-cyan-950 text-white text-sm w-fit py-1 px-2 rounded-md mx-auto cursor-pointer hover:scale-110 self-end"
              >
                <p>Reset</p>
              </div>
              <div class="w-5/6 mt-10 mx-auto">
                <AllocationTable data={tableData} headData={headData} />
              </div>

              <div class="flex justify-center my-16 mx-auto w-2/3">
                <Card title={"Allocation Logics used"} content={logics} />
              </div>
            </div>
          ) : (
            <div class="flex justify-center items-center mt-3">
              <LoaderComp />
            </div>
          )}{" "}
        </>
      )}
    </div>
  );
};

export default Allocation;
