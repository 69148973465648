import React, { useEffect, useState } from "react";
import { AgChartsReact } from "ag-charts-react";

const LineChart = (props) => {
  const [lineData, setLineData] = useState(props.data);
  const [annotations, setAnnotations] = useState(props.annotations);
  // console.log("Annotations", annotations);

  useEffect(() => {
    setLineData(props.data);
  }, [props]);

  const options = {
    autoSize: true,
    height: 600,
    data: lineData,
    title: {
      text: props.text,
      fontSize: 18,
      spacing: 25,
    },
    // annotations: {
    //   initial: [
    //     {
    //       type: "line",
    //       locked: true,
    //       start: { x: annotations && annotations.length && annotations[0], y: 500 },
    //       end: { x: annotations && annotations.length && annotations[annotations.length - 1], y: 1000000 },
    //       stroke: "#247BA0",
    //       strokeWidth: 2,
    //     },
    //   ],
    // },
    series: [
      {
        type: "line",
        xKey: "date",
        yKey: props.yKey,
        stroke: "#247BA0",
        marker: {
          enabled: false,
        },
      },
    ],

    axes: [
      {
        type: "number",
        position: "left",
        gridLine: {
          enabled: true,
          style: [
            {
              stroke: "lightgrey",
              lineDash: [10, 5],
            },
          ],
        },
        // label: {
        // 	formatter: function (params) {
        // 		// Check if params.value is a number and format it as a percentage
        // 		if (!isNaN(params.value)) {
        // 			return params.value.toFixed(2) + "%";
        // 		}
        // 		// Return the original value if it's not a number
        // 		return params.value;
        // 	},
        // },
      },
      {
        type: "category",

        position: "bottom",
        label: {
          autoRotate: false,
        },
        gridLine: {
          enabled: true,
          style: [
            {
              stroke: "lightgrey",
              lineDash: [10, 5],
            },
          ],
        },
      },
    ],
  };

  return <AgChartsReact options={options} />;
};

export default LineChart;
