"use strict";

import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  StrictMode,
} from "react";
// import { createRoot } from 'react-dom/client';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
// Sample data

// Define the React component
const GroupedTable = (props) => {
  
  const currencyFormatter = (params) => {
    if (params.value === "N/A") return "";
    if (params.value !== 0 && !params.value) return "";
    return typeof params.value === Number
      ? params.value.toLocaleString()
      : Math.floor(params.value).toLocaleString();
  };


  // Define column definitions
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Year",

      minWidth: 200,
      showRowGroup: "year",
      cellRenderer: "agGroupCellRenderer",
      suppressMovable: true,
      pinned: "left",
    },
    {
      headerName: "Strategy",

      minWidth: 200,
      showRowGroup: "strategy",
      cellRenderer: "agGroupCellRenderer",
      suppressMovable: true,

      pinned: "left",
    },

    {
      field: "year",
      rowGroup: true,
      hide: true,
      sortable: true,
      sort: "asc",
    },
    {
      field: "strategy",
      rowGroup: true,
      suppressMovable: true,
      sortable: true,

      hide: true,
    },
    {
      field: "subStrategy",
      headerName: "Substrategy",
      valueFormatter: (params) => {
        if (params.value) {
          if (params.value === "_") return "_";
          else return params.value.substring(0, params.value.lastIndexOf("_"));
        }
        else return ""
      },
    },
    {
      headerName: "Net Pnl",
      field: "netpnl",
      suppressMovable: true,
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: currencyFormatter,
      aggFunc: (params) => {

        if (
          !props.yearStrategyWiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
          ) ||
          params.rowNode.parent.key === null
        )
          return "N/A";
        return props.yearStrategyWiseMap[
          `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["netpnl"];
      },

    },
    {
      headerName: "Thr Pnl",
      field: "thrpnl",
      cellStyle: (params) => {
        if (parseFloat(params.value) >= 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: currencyFormatter,
      aggFunc: (params) => {
        if (
          !props.yearStrategyWiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
          ) ||
          params.rowNode.parent.key === null
        )
          return "N/A";
        return props.yearStrategyWiseMap[
          `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["thrpnl"];
      },
    },

    {
      headerName: "Worst Day Pnl",
      field: "worstPnl",
      cellStyle: (params) => {
        if (parseFloat(params.value) > 0)
          return { color: "green", fontWeight: 500 };
        else return { color: "red", fontWeight: 500 };
      },
      valueFormatter: currencyFormatter,
      aggFunc: (params) => {
        if (
          !props.yearStrategyWiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
          ) ||
          params.rowNode.parent.key === null
        )
          return "N/A";
        return props.yearStrategyWiseMap[
          `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["worstPnl"];
      },
    },
    {
      field: "mdd",
      cellStyle: { color: "red", fontWeight: 500 },
      valueFormatter: currencyFormatter,
      aggFunc: (params) => {

        if (
          !props.yearStrategyWiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
          ) ||
          params.rowNode.parent.key === null
        )
          return "N/A";
        return props.yearStrategyWiseMap[
          `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["mdd"];
      },
    },
    {
      field: "cdd",
      cellStyle: { color: "red", fontWeight: 500 },
      valueFormatter: currencyFormatter,
      aggFunc: (params) => {

        if (
          !props.yearStrategyWiseMap.hasOwnProperty(
            `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
          ) ||
          params.rowNode.parent.key === null
        )
          return "N/A";
        return props.yearStrategyWiseMap[
          `${params.rowNode.allLeafChildren[0].data.strategy} ${params.rowNode.allLeafChildren[0].data.year}`
        ]["cdd"];
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 150,
      // sortable: true,
      resizable: true,
      // sort: "desc",
    };
  }, []);
  // Set the grid data
  const gridOptions = {
    columnDefs: columnDefs,
    rowData: props.tableData,
    suppressAggFuncInHeader: true,
    defaultColDef: defaultColDef,
  };
  //   const [gridData, setGridData] = useState(rowData);
  // Ag-Grid events
  const onGridReady = (params) => {
    // You can do additional setup here if needed
    return <span style={{ marginLeft: 60 }}>{params.value}</span>;
  };
  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: 500,
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <AgGridReact
        gridOptions={gridOptions}
        onGridReady={onGridReady}
        animateRows={true}
        groupDisplayType={"custom"}
        groupHideOpenParents={true}
      />
    </div>
  );
};
// Export the component
export default GroupedTable;
