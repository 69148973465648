import { useMemo } from "react";
import {
  setEmail,
  setToken,
  setTokenExpiryTime,
} from "../redux/slices/credentials";

export const convertDate = (date) => {
  let month = date.slice(4, 6);
  const day = date.slice(6);
  const year = date.slice(0, 4);
  if (parseInt(month) < 10) {
    month = month[1];
  }
  return `${day}/${month}/${year}`;
};

export const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month as it starts from 0
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}${month}${day}`;
};

export const dateToString = (date) => {
  const month = parseInt(date.getMonth()) + 1;
  const day = parseInt(date.getDate());
  const year = parseInt(date.getFullYear());

  return `${year}-${month}-${day}`;
};
export const DOTS = "...";

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};
export const checkYearsContinuity = (years) => {
  years.sort((a, b) => a - b);

  for (let i = 0; i < years.length - 1; i++) {
    if (parseInt(years[i + 1]) - parseInt(years[i]) !== 1) return false;
  }
  return true;
};

// export const check = (date_st, date, date_end) => {
//   // console.log(date_st, date, date_end)
//   const month_st = parseInt(date_st.getMonth()) + 1;
//   const year_st = parseInt(date_st.getFullYear());
//   const day_st = parseInt(date_st.getDate());

//   const month_end = parseInt(date_end.getMonth()) + 1;
//   const year_end = parseInt(date_end.getFullYear());
//   const day_end = parseInt(date_end.getDate());

//   const month = parseInt(date.slice(4, 6));
//   const year = parseInt(date.slice(0, 4));
//   const day = parseInt(date.slice(6));

//   if (year > year_st && year < year_end) {
//     return true;
//   }
//   if (year === year_st && year < year_end) {
//     if (month > month_st) return true;
//     else if (month === month_st) {
//       if (day >= day_st) return true;
//       else return false;
//     } else return false;
//   }
//   if (year > year_st && year === year_end) {
//     if (month < month_end) return true;
//     else if (month === month_end) {
//       if (day <= day_end) return true;
//       else return false;
//     } else return false;
//   }
//   if (year === year_st && year === year_end) {
//     if (month < month_end && month > month_st) return true;
//     if (month === month_end && month > month_st) {
//       if (day <= day_end) return true;
//       else return false;
//     }
//     if (month < month_end && month === month_st) {
//       if (day >= day_st) return true;
//       else return false;
//     }
//     if (month === month_end && month === month_st) {
//       if (day >= day_st && day <= day_end) return true;
//       else return false;
//     }
//   }
//   return false;
// };

const range = (start, end) => {
  let length = end - start + 1;
  /*
  	Create an array of certain length and set the elements within it from
    start value to end value.
  */
  return Array.from({ length }, (_, idx) => idx + start);
};

export const haveCommonElements = (arr1, arr2) => {
  const set1 = new Set(arr1);
  for (const element of arr2) {
    if (set1.has(element)) {
      return true; // Found a common element
    }
  }
  return false;
};

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 0,
  currentPage,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + 5;

    /*
      Case 1:
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount);
    }

    /*
    	Calculate left and right sibling index and make sure they are within range 1 and totalPageCount
    */
    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    );

    /*
      We do not show dots just when there is just one page number to be inserted between the extremes of sibling and the page limits i.e 1 and totalPageCount. Hence we are using leftSiblingIndex > 2 and rightSiblingIndex < totalPageCount - 2
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    /*
    	Case 2: No left dots to show, but rights dots to be shown
    */
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, totalPageCount];
    }

    /*
    	Case 3: No right dots to show, but left dots to be shown
    */
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * siblingCount;
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      );
      return [firstPageIndex, DOTS, ...rightRange];
    }

    /*
    	Case 4: Both left and right dots to be shown
    */
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [totalCount, pageSize, siblingCount, currentPage]);

  return paginationRange;
};

export const getHiddenValues = (arr1, arr2) => {
  const arr = [];
  arr1 &&
    arr1.length &&
    arr1.forEach((val) => {
      if (arr2.indexOf(val) === -1) arr.push(val);
    });
  return arr;
};

export const formatDataForPieChart = (data) => {
  return data.map((item) => ({
    category: item.category,
    netpnl: item.netpnl,
  }));
};

export const convertToSavedFormat = (dateStr) => {
  const date = new Date(dateStr);
  let month = parseInt(date.getMonth()) + 1;
  if (month < 10) {
    month = ("0" + month.toString()).toString();
  }
  const year = parseInt(date.getFullYear()).toString();
  let day = parseInt(date.getDate()).toString();
  if (day < 10) {
    day = ("0" + day.toString()).toString();
  }
  // console.log('date', year+month+day)
  return year + month + day;
};

// const firstPageIndex = (currentPage - 1) * pageSize;
// const lastPageIndex = firstPageIndex + pageSize;
// const currentTableData = data.slice(firstPageIndex, lastPageIndex);
// setCurrTableData(currentTableData)

export const calculateRollingMean = (data, n, multiplyingFactor) => {
  const rollingMean = [];
  for (let i = 0; i < data.length; i++) {
    if (i < n - 1) {
      // If there are not enough previous elements for the window, push NaN
      rollingMean.push(null);
      continue;
    }

    // Calculate the mean of the window
    const window = data.slice(i - n + 1, i + 1);
    const mean =
      window.reduce((sum, value) => sum + value * multiplyingFactor, 0) / n;
    rollingMean.push(mean);
  }

  return rollingMean;
};

export const calculateRankOfLastValue = (data, y) => {
  const lastValue = data[data.length - 1];
  const sortedData = data.slice(-y).sort((a, b) => a - b);
  const rank = sortedData.indexOf(lastValue) + 1;
  return rank;
};

export const capitalize = (word) => {
  const [firstLetter, ...rest] = word;
  return firstLetter.toUpperCase() + rest.join("");
};

export const calculateRollingPercentile = (data, days) => {
  data = data.map((item) => (item === null ? 0 : item));

  //  console.log("rollingData", data)
  if (data.length < days) {
    const percentiles = Array(data.length).fill(null);
    return percentiles;
  }

  let percentiles = [];
  // Calculate percentile for each element based on rolling window
  data.forEach((value, index) => {
    if (index >= days - 1) {
      const window = data.slice(index - days + 1, index + 1);
      window.sort((a, b) => a - b); // Sort the window
      const percentileIndex = window.indexOf(value) + 1;
      const percentile = (percentileIndex * 100) / days;
      percentiles.push(percentile);
    } else {
      percentiles.push(null);
    }
  });

  return percentiles;
};

export const getFilteredData = (
  originalData,
  symbols,
  rollingDays,
  numberOfDays,
  multiplyingFactor,
  properties
) => {
  let allData = [];
  let percentiles = [];
  let propertyObj = {};
  // console.log("mutli", multiplier)
  for (let index in symbols) {
    let symbol = symbols[index];
    let symbolData = originalData.filter((obj) => obj.underlying === symbol);
    for (let idx in properties) {
      const property = properties[idx];
      const volumes = symbolData.map((item) => item[property]);
      let rollingMean = calculateRollingMean(
        volumes,
        rollingDays,
        multiplyingFactor
      );
      let check = rollingMean.every((value) => value === null);
      if (!check)
        percentiles = calculateRollingPercentile(rollingMean, numberOfDays);
      else percentiles = Array(symbolData.length).fill(null);
      let updatedRollingMean = `rollingMean_${parseInt(idx) + 1}`;
      let updatedPercentiles = `percentile_${parseInt(idx) + 1}`;
      propertyObj[updatedRollingMean] = rollingMean;
      propertyObj[updatedPercentiles] = percentiles;
    }

    symbolData.forEach((obj, index) => {
      let newObj = obj;
      for (let key in propertyObj) {
        // console.log("key", key);
        newObj = {
          ...newObj,
          [key]: propertyObj[key][index],
        };
      }

      allData.push(newObj);
    });
  }
  return allData;
};

export const clearUserSession = (dispatch) => {
  localStorage.removeItem("token");
  dispatch(setToken(null));
  dispatch(setEmail(null));
  dispatch(setTokenExpiryTime(null));
};

export const getMarketChartData = (data, symbolGraph, property) => {
  let chartTempData = data
    .filter((item) => item.underlying === symbolGraph)
    .map((item) => ({
      date: item.date,
      [property]: item[property],
    }));

  chartTempData = chartTempData.filter((item) => item[property] != null);
  let firstDate = new Date();
  if (chartTempData.length > 0) {
    firstDate = chartTempData[0].date;
    let year = firstDate.substring(0, 4);
    let month = firstDate.substring(4, 6) - 1;
    let day = firstDate.substring(6, 8);

    firstDate = new Date(year, month, day);
  }

  return { chartTempData, firstDate };
};

export const downloadLocally = (fileName, response) => {
  const blob = new Blob([response.data], {
    type: "application/json",
  });

  // Create a link element and trigger a download
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${fileName}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getCombinedExpData = (
  property,
  exp,
  filteredData,
  symbolGraph,
  nonNullData
) => {
  let nonExpData = nonNullData && nonNullData.length > 0 && nonNullData.filter(
    (item) => item.days_to_expiry.toString() !== exp
  );
  let expData = filteredData && filteredData.length > 0 && filteredData.filter(
    (item) => item.days_to_expiry.toString() === exp
  );

  let obj1 = getMarketChartData(expData, symbolGraph, property);
  let obj2 = getMarketChartData(nonExpData, symbolGraph, property);

  let data1 = obj1.chartTempData;
  let data2 = obj2.chartTempData;
  let tempData = [...data1];
  tempData.sort((a, b) => b.date - a.date);

  let finalNonExpData = data2.map((item1) => {
    const foundObj = tempData.find((item2) => item2.date <= item1.date);
    if (foundObj) {
      // console.log("Date", item1.date, foundObj.date);
      return {
        ...item1,
        [property]: foundObj[property],
      };
    } else return { ...item1 };
  });

  data1 = data1.concat(finalNonExpData);
  data1.sort((a, b) => a.date - b.date);

  return data1;
};
