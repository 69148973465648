import { TailSpin } from "react-loader-spinner";
import React from "react";

const LoaderComp = () => {
  return (

    <div class="animation-spin">
      <div class="w-14 h-14 border-2 border-solid border-grey-400 border-t-2 border-t-solid border-t-black border rounded-full animate-spin"></div>
    </div>
  );
};

export default LoaderComp;
