import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearUserSession } from "../utils";

const SessionExpiredDialog = (props) => {
  const [isOpen, setIsOpen] = useState(props.expired);

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => {
    setIsOpen(false);
    clearUserSession(dispatch);
    navigate("/");
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75">
          <div className="bg-white p-8 rounded-lg shadow-md">
            <h2 className="text-2xl font-semibold mb-4">Session Expired</h2>
            <p className="text-gray-700 mb-4">
              Your session has expired. Please login again.
            </p>
            <button
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
              onClick={closeDialog}
            >
              Login
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SessionExpiredDialog;
