// redux/slices/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

// Define the initial state for the slice
const initialState = {
  token: null,
  email: null,
  tokenExpiryTime : null,
};

// Create the slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setTokenExpiryTime: (state, action) => {
      state.tokenExpiryTime = action.payload;
    },
  },
});

// Export the actions
export const { setToken, setEmail, setTokenExpiryTime} = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
